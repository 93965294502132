import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-scroll';
import { useNavigate } from "react-router-dom"
import logo from '../../assets/logo.png'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"

export default function NewMobileMenu() {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('home');
    const trigger = useRef(null);
    const mobileNav = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const navigate = useNavigate();
    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!mobileNav.current || !trigger.current) return;
            if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!mobileNavOpen || keyCode !== 27) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleLinkClick = () => {
        setMobileNavOpen(false);
    };
    const handleSetActiveLink = (link) => {
        setActiveLink(link);
    };

    const hamburgerClass = mobileNavOpen ? 'active' : '';

    const [size, setSize] = useState(null);
    const handleOpen = (value) => setSize(value);

    return (
        <div className="lg:hidden">
            {/* Hamburger button */}
            <button
                ref={trigger}
                className={`hamburger ${hamburgerClass} pt-2`}
                aria-controls="mobile-nav"
                aria-expanded={mobileNavOpen}
                onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
                <svg
                    className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect y="4" width="24" height="2" rx="1" />
                    <rect y="11" width="24" height="2" rx="1" />
                    <rect y="18" width="24" height="2" rx="1" />
                </svg>
            </button>

            {/* Mobile navigation */}
            <nav
                id="mobile-nav"
                ref={mobileNav}
                className={`py-10 px-10 fixed inset-0 z-20 w-full h-screen flex items-end justify-end transition-all duration-500 ease-in-out transform ${mobileNavOpen ? 'translate-x-0' : 'translate-x-full'
                    }`}
            >

                <div
                    className="absolute top-5 left-5 z-30  rounded-full text-gray-100 flex items-center justify-center focus:outline-none"
                >
                    <img src={logo} className="h-8 mr-3" alt="Logo" />
                </div>
                {/* X button */}
                {mobileNavOpen && (
                    <button
                        className="absolute top-4 right-4 z-30 w-10 h-10  rounded-full text-gray-100 flex items-center justify-center focus:outline-none"
                        onClick={() => setMobileNavOpen(false)}
                    >
                        <svg
                            className="w-6 h-6 fill-current"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.41421 5.41421C5.80474 5.02369 6.4379 5.02369 6.82843 5.41421L12 10.5858L17.1716 5.41421C17.5621 5.02369 18.1953 5.02369 18.5858 5.41421C18.9763 5.80474 18.9763 6.4379 18.5858 6.82843L13.4142 12L18.5858 17.1716C18.9763 17.5621 18.9763 18.1953 18.5858 18.5858C18.1953 18.9763 17.5621 18.9763 17.1716 18.5858L12 13.4142L6.82843 18.5858C6.4379 18.9763 5.80474 18.9763 5.41421 18.5858C5.02369 18.1953 5.02369 17.5621 5.41421 17.1716L10.5858 12L5.41421 6.82843C5.02369 6.4379 5.02369 5.80474 5.41421 5.41421Z"
                            />
                        </svg>
                    </button>
                )}

                <ul className="flex flex-col items-end justify-end text-xl gap-2 uppercase font-[600]">
                    <li className={`cursor-pointer block py-2 text-gray-100 rounded hover:text-[#ceae7b] ${activeLink === 'home' ? 'text-[#ceae7b]' : 'text-white'
                        }`}>
                        <Link to="home" spy={true} smooth={true} duration={500} onClick={handleLinkClick} onSetActive={handleSetActiveLink}>
                            Home
                        </Link>
                    </li>
                    <li className={`cursor-pointer block py-2 text-gray-100 rounded hover:text-[#ceae7b] ${activeLink === 'XureDealWorks' ? 'text-[#ceae7b]' : 'text-white'
                        }`}>
                        <Link
                            to="beAnXpert"
                            spy={true}
                            smooth={true}
                            duration={500}
                            onClick={handleLinkClick}
                            onSetActive={handleSetActiveLink}
                        >
                            Apply As An Xpert
                        </Link>
                    </li>
                    <li className={`cursor-pointer block py-2 text-gray-100 rounded hover:text-[#ceae7b] ${activeLink === 'contactUs' ? 'text-[#ceae7b]' : 'text-white'
                        }`}>
                        <Link
                            to="xtoreSection"
                            spy={true}
                            smooth={true}
                            duration={500}
                            onClick={handleLinkClick}
                            onSetActive={handleSetActiveLink}
                        >
                            Be An Accredited Xtore
                        </Link>
                    </li>
                    <li className={`cursor-pointer block py-2 text-gray-100 rounded `}>
                        <div className="shrink-0 ml-4">
                            <button
                                // onClick={() => navigate('collectorsQuiz')}
                                onClick={() => handleOpen("xs")}
                                className="hover:bg-[#ceae7b] px-3 py-3 rounded-xl border-2 hover:border-white border-[#ceae7b] text-[#ceae7b] hover:text-white shadow-lg flex items-center justify-center gap-2"
                            >
                                Discover your inner Collector
                            </button>
                        </div>
                    </li>

                </ul>
            </nav>
            <Dialog open={size === "xs"} size={size || "md"} handler={handleOpen}>
                <DialogBody className='text-center'>
                    <p className='text-[#121212]'>
                        Quiz loading soon!
                    </p>
                </DialogBody>
            </Dialog>
        </div>
    );
}
