import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import logoX from '../assets/Logo-X.png';
import MobileMenu from './MobileMen';
import { useNavigate } from "react-router-dom"
import { useViewportScroll, useTransform } from 'framer-motion';
import logo from '../assets/logo.png'
import favicon from '../assets/favicon.png'
export default function NavigationBar() {

  const navigate = useNavigate();
  const { scrollYProgress } = useViewportScroll();
  const scaleX = useTransform(scrollYProgress, [0, 1], [0, 1]);

  const [activeLink, setActiveLink] = useState('home');

  const handleSetActiveLink = (link) => {
    setActiveLink(link);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-[#161616] fixed w-full min-w-screen z-30 lg:px-10 flex items-center px-7 md:px-10 xl:px-20">
      <div className="w-full">
        <div className="flex items-center justify-between h-14">
          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <Link href="home" className="block lg:hidden" aria-label="Cruip">
              <img src={favicon} className="h-8 mr-3" alt="Logo" />
            </Link>
            <Link href="home" className="lg:block hidden" aria-label="Cruip">
              <img src={logo} className="h-8 mr-3" alt="Logo" />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop sign in links */}
            <ul className="flex grow justify-end flex-wrap items-center uppercase font-[500] md:text-sm space-x-8 pr-3">
              <Link
                to="home"
                spy={true}
                smooth={true}
                duration={600}
                onSetActive={handleSetActiveLink}
              >
                <li
                  className={`cursor-pointer block py-2 pl-3 pr-4 rounded hover:text-[#ceae7b] md:p-0 ${activeLink === 'home' ? 'text-[#ceae7b]' : 'text-white'
                    }`}
                >
                  Home
                </li>
              </Link>
              <Link
                to="XureDealWorks"
                spy={true}
                smooth={true}
                duration={600}
                onSetActive={handleSetActiveLink}
              >
                <li
                  className={`cursor-pointer block py-2 pl-3 pr-4 rounded hover:text-[#ceae7b] md:p-0 ${activeLink === 'XureDealWorks' ? 'text-[#ceae7b]' : 'text-white'
                    }`}
                >
                  How it works
                </li>
              </Link>


              {/* Authenticate dropdown */}
              {/* <div className="relative inline-block text-left">
                <div>
                <button
                  type="button"
                  className={`inline-flex w-full justify-center gap-x-1.5 uppercase rounded-md bg-[#161616]  px-3 py-2 text-sm  ${
                    isMenuOpen ? 'text-[#ceae7b]' : 'text-white'
                  }`}
                  id="menu-button"
                  aria-expanded={isMenuOpen}
                  aria-haspopup="true"
                  onClick={handleMenuToggle}
                >
                 xdeals
                  <svg
                    className={`-mr-1 h-5 w-5 text-gray-400 transform ${
                      isMenuOpen ? 'rotate-180' : ''
                    }`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                </div>
                {isMenuOpen && (
                  <div
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-[#161616] border-2 border-[#ceae7b] shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                  >
                    <div className="py-1" role="none">
                     // first link
                    <Link
                      to="xhibition"
                      spy={true}
                      smooth={true}
                      duration={600}
                      onSetActive={handleSetActiveLink}
                    >
                      <li
                        className={`cursor-pointer hover:text-[#ceae7b] block px-4 py-2 text-sm ${
                          activeLink === 'xhibition' ? 'text-[#ceae7b]' : ' text-gray-700'
                        }` }
                      >
                        xhibition
                      </li>
                  </Link>

                  // second link 
                  <Link
                    to="xperts"
                    spy={true}
                    smooth={true}
                    duration={600}
                    onSetActive={handleSetActiveLink}
                  >
                    <li
                      className={`cursor-pointer hover:text-[#ceae7b] px-4 block py-2 text-sm ${
                        activeLink === 'xperts' ? 'text-[#ceae7b]' : 'text-gray-700'
                      }`}
                    >
                      xperts
                    </li>
                </Link>
                // third link 
                  <Link
                    to="authenticate"
                    spy={true}
                    smooth={true}
                    duration={600}
                    onSetActive={handleSetActiveLink}
                  >
                    <li
                      className={`cursor-pointer hover:text-[#ceae7b] px-4 block py-2 text-sm ${
                        activeLink === 'authenticate' ? 'text-[#ceae7b]' : 'text-gray-700'
                      }`}
                    >
                      xertify
                    </li>
                </Link>
                    </div>
                  </div>
                )}
              </div> */}
              {/* Rest of the links */}

              <li
                className={`cursor-pointer block py-2 pl-3 pr-4 rounded hover:text-[#ceae7b] md:p-0 ${activeLink === 'contactUs' ? 'text-[#ceae7b]' : 'text-white '
                  }`}
              ><button onClick={() => navigate('/support')} className='uppercase'>
                  Contact Us
                </button>
              </li>

            </ul>
          </nav>
        </div>
      </div>
      {/* Mobile navigation */}
      <MobileMenu
        activeLink={activeLink}
        handleSetActiveLink={handleSetActiveLink}
      />
      <motion.div className="h-1 bg-[#ceae7b] z-50" style={{ scaleX }} />
    </header>
  );
}
