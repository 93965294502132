import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate  } from "react-router-dom"

export default function InvalidPage() {
    const navigate = useNavigate();
    return (

        <div className="bg-[#161616] py-10 w-full min-w-screen h-full min-h-screen items-center justify-center flex flex-col font-sans text-white">
            <p className='text-[3rem]'>Page not found</p>
            <p>Please back to our <button onClick={()=> navigate('/')} className='underline underline-offset-4 hover:text-[#ceae7b]'> Homepage </button></p>
        </div>
    );
}