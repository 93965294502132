import { React, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Spinner,
} from "@material-tailwind/react"
import { FaArrowLeft, FaRegCircleCheck } from "react-icons/fa6";
import { MdOutlineError } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import axios from "axios"
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png'

export default function CollectorsQuiz() {

    const navigate = useNavigate();
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [animate, setAnimate] = useState(true);

    // Array of questions with unique options for each
    const questions = [
        {
            text: "Question 1: What’s the main reason you collect items?",
            options: [
                "A) I love the beauty and design of each piece.",
                "B) They help me preserve and celebrate history.",
                "C) I believe they’ll increase in value over time.",
                "D) I want to complete a full set—it’s my mission!",
                "E) Each item has a personal story or memory for me.",
                "F) I love using the items I collect.",
                "G) I’m an expert in my collection category.",
                "H) I admire the connection to famous people or events.",
                "I) I focus on a specific theme or topic that fascinates me.",
                "J) These items remind me of a particular era or culture I love.",
            ],
        },
        {
            text: "Question 2: How do you feel about reselling items from your collection?",
            options: [
                "Weekly",
                "Monthly",
                "A few times a year",
                "Whenever I find the right piece",
            ],
        },
        {
            text: "What’s your most valued piece?",
            options: [
                "A rare item I acquired recently",
                "A piece with sentimental value",
                "An item signed by someone famous",
                "A piece that’s part of a limited edition",
            ],
        },
        {
            text: "Do you display your collection publicly?",
            options: [
                "Yes, I have a dedicated display space",
                "Only for close friends and family",
                "I showcase it online",
                "No, I keep it private",
            ],
        },
        {
            text: "How do you decide which items to collect?",
            options: [
                "Based on aesthetic appeal",
                "Historical relevance",
                "Investment potential",
                "Completing a themed set",
            ],
        },
        {
            text: "Do you collect items from specific time periods?",
            options: [
                "Yes, only from certain decades",
                "No, I collect items from any period",
                "I prefer vintage pieces",
                "I focus on modern collectibles",
            ],
        },
        {
            text: "How long have you been collecting?",
            options: [
                "Less than a year",
                "1-5 years",
                "5-10 years",
                "Over 10 years",
            ],
        },
        {
            text: "Do you research each item’s history?",
            options: [
                "Always, it’s part of the fun",
                "Sometimes, if it’s an interesting piece",
                "Only for high-value items",
                "Rarely, I enjoy them as they are",
            ],
        },
        {
            text: "What is the biggest challenge in collecting?",
            options: [
                "Finding authentic items",
                "Managing storage space",
                "Dealing with high prices",
                "Explaining my hobby to others",
            ],
        },
        {
            text: "How do you connect with other collectors?",
            options: [
                "Through online forums",
                "At trade shows or fairs",
                "Via social media groups",
                "I don’t actively connect with others",
            ],
        },
    ];

    const handleNext = () => {
        setAnimate(false);
        setTimeout(() => {
            setCurrentQuestion((prev) => Math.min(prev + 1, questions.length));
            setAnimate(true);
        }, 100); // Short delay to remove and reapply the class
    };

    const handleBack = () => {
        setAnimate(false);
        setTimeout(() => {
            setCurrentQuestion((prev) => Math.max(prev - 1, 1));
            setAnimate(true);
        }, 100);
    };

    // Reusable Question Options Component
    const QuestionOptions = ({ options }) => {
        return (
            <div className="mt-6 space-y-3">
                {options.map((option, index) => (
                    <div key={index} className="flex items-center gap-x-3">
                        <input
                            id={`option${index}`}
                            name="push-notifications"
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label
                            htmlFor={`option${index}`}
                            className="block text-[#ffffff] text-sm/6 font-normal"
                        >
                            {option}
                        </label>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div id='collectorsQuiz' className='background-image-4 min-w-screen min-h-screen h-full w-full flex overflow-hidden selector'>
            <div className='z-10 flex flex-col items-center w-full justify-end gap-[2rem] text-white  overflow-hidden '>

                <div className='parallax_section items-center w-full h-full px-10 xl:px-20 lg:py-20 py-10 '>
                    <div className='flex w-full flex-col h-full items-center  justify-center gap-[2rem]  max-w-[1440px] 2xl:m-auto'>
                        <div className='w-full'>
                            <button onClick={() => navigate('/')} className="flex-row flex items-center gap-2 text-[#ceae7b] cursor-pointer sm:w-full sm:text-xs md:text-lg">
                                <FaArrowLeft />
                                <span>Back to Website</span>
                            </button>
                        </div>
                        <div className='flex flex-col justify-center mt-10 w-full lg:justify-center lg:items-center gap-10'>
                            <div className='flex justify-center items-center gap-3 flex-row '>
                                <div className='text-center text-[#ffffff] font-semibold text-2xl lg:text-[2.4rem] xl:text-[2.5rem]'>
                                    What Type of Collector Are You?
                                </div>
                            </div>
                            <p className='text-center tracking-wide text-[#ffffff] text-[1rem] lg:text-[1.3rem] xl:text-[1.3rem]'>
                                <span className='font-semibold'>Instructions:</span> Choose the answer that best describes you for each question, then count how many times you selected each letter to discover your collector type.
                            </p>
                        </div>
                        <div className='flex flex-col justify-end w-full lg:justify-end lg:items-end gap-3'>
                            <div className='w-full flex flex-col lg:gap-[5rem] gap-[3rem] items-center'>
                                <div className='w-full flex flex-col border border-[#343434] p-10 rounded bg-[#31313169]'>
                                    {/* Display the current question */}
                                    <div
                                        id={`question${currentQuestion}`}
                                        className={`flex flex-col gap-[2rem] ${animate ? 'fade-in' : ''}`}
                                    >
                                        <p className="text-center font-semibold tracking-wide text-[#ffffff] text-[1rem] lg:text-[1.3rem] xl:text-[1.3rem]">
                                            {questions[currentQuestion - 1].text}
                                        </p>
                                        <QuestionOptions options={questions[currentQuestion - 1].options} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper w-full flex justify-end w-full ">
                            {currentQuestion > 1 && (
                                <button onClick={handleBack} className="btn-apply sm:w-full sm:text-xs lg:text-sm md:text-lg">
                                    Back
                                </button>
                            )}
                            {currentQuestion < questions.length ? (
                                <button onClick={handleNext} className="btn-apply sm:w-full sm:text-xs lg:text-sm md:text-lg">
                                    Continue
                                </button>
                            ) : (
                                <Link href="#" className="btn-apply sm:w-full sm:text-xs  lg:text-sm md:text-lg">
                                    Submit
                                </Link>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}