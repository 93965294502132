import React, { useState, useEffect } from 'react';
import { FaHome } from "react-icons/fa";
import { MdHomeRepairService, MdPermDeviceInformation, MdQrCodeScanner } from "react-icons/md";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import AOS from 'aos';
import 'aos/dist/aos.css';


import desktop from '../assets/bgDesktop.jpg';
import mobile from '../assets/bgMobile.jpg';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Authenticate() {
  useEffect(() => {
    AOS.init();
  }, []);

    const [value, setValue] = useState('')

    const data = (value)? "Invalid Item Code" : "No Item Code Detected!"

    const invalid = () => toast.error(data, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    

    return (
      <div className="h-full min-h-screen flex items-center justify-center mx-auto px-4 py-5 w-full min-w-screen relative" id="authenticate">
        <div className="background-imagePlus" />
  
        <div className="flex flex-col justify-center text-center lg:text-start items-center w-full h-screen text-gray-300">
          <div className=" lg:flex flex-col justify-center items-center card-container">
            <div className="text-center mb-5 capitalize lg:mt-0 z-10">
              <label className="font-bold lg:text-4xl md:text-4xl 2xl:text-5xl sm:text-2xl " data-aos="fade-up" data-aos-duration="1500">Enter Item Code</label>
            </div>
            <div className="mt-10 flex w-full justify-center items-center"  data-aos="fade-up" data-aos-duration="1000">
              <div className="w-[250px] md:w-[400px] lg:w-[500px] relative z-10 flex items-between">
                <input className="font-[500] placeholder:font-[500] placeholder:text-neutral-600 block w-full  m-auto border border-gray-400 rounded-md py-3 pl-5 pr-3 shadow-sm focus:outline-none focus:border-[#ceae7b] focus:ring-[#ceae7b] focus:ring-1 sm:text-sm bg-[#161616]" placeholder="Enter Item Code" type="text" name="search" value={value} onChange={(e)=>setValue(e.target.value)} />
      
              </div>
              <div className=" pt-[5px] pl-2 text-[#ceae7b]  md:hidden">
              <button onClick={invalid} className='z-20'>
                  <MdQrCodeScanner size={48} />
                </button>

              </div>
              <div className=" pt-[5px] pl-2 text-[#ceae7b] z-10 hidden md:block">
                <button onClick={invalid}>
                  <BsFillArrowRightSquareFill size={48} className='z-20' />
                </button>
              </div>
              
            </div>
          </div>
        </div>
  
        <style jsx>{`
          .background-imagePlus {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            filter: brightness(60%);
          }
  
          #authenticate {
            position: relative;
          }
  
          @media (min-width: 1024px) {
            .background-imagePlus {
              background-image: url(${desktop});
              background-attachment: fixed;
            }
          }
  
          @media (max-width: 1023px) {
            .background-imagePlus {
              background-image: url(${mobile});
            }
  
            .card-container {
              position: relative;
              z-index: 1;
            }
          }
        `}</style>
       
      </div>
    );
  }
  
  
  
  