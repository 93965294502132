import {React, useEffect} from "react"
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Xchange(){

    useEffect(() => {
        AOS.init();
      }, []);
    return(
        <div className="w-screen min-w-full h-screen min-h-full flex justify-center items-center p-10 bg-[#161616]" id="xhibition">
            <ul className="w-[50rem] h-full text-white flex flex-col justify-center items-center text-center gap-3 ">
            <li className="text-md text-3xl md:text-4xl lg:text-5xl xl:text-6xl  2xl:text-7xl capitalize font-[500] text-[#ceae7b]" data-aos="fade-up" data-aos-duration="1500">
                    coming soon!
                </li>
            <li className="text-md md:text-3xl 2xl:text-5xl capitalize font-[500] mt-5" data-aos="fade-up" data-aos-duration="1000">
                    Your Xhibition
                </li>
               
                <li className="mt-2" data-aos="fade-up" data-aos-duration="2000">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et odio pellentesque diam volutpat commodo. Vitae nunc sed velit dignissim sodales ut eu sem integer.
                </li>
                
            </ul>

        </div>
    )
}