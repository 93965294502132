import { React, useState } from 'react'
import logo from '../assets/logo.png'
import googlePlay from '../assets/googlePlay.png'
import appStore from '../assets/appStore.png'
import phone from '../assets/phone.png'
import testFlightQR from '../assets/testFlightQR.png'
import googlePlayQR from '../assets/googlePlayQR.png'
import googlePlayLogo from '../assets/googlePlayLogo.png'
import appStoreLogo from '../assets/appStoreLogo.png'

import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"

function XureAppHome() {

    const [size, setSize] = useState(null);
    const handleOpen = (value) => setSize(value);
    const [size2, setSize2] = useState(null);
    const handleOpen2 = (value) => setSize2(value);

    return (
        <div id='home' className='min-w-screen w-full min-h-screen h-full flex items-center justify-center px-10 md:pt-[2rem] xl:px-20 lg:pt-0 overflow-hidden selector pt-2'>
            <div className='background-image -z-10 overflow-y-hidden  ' />



            <ul className='flex flex-col lg:flex-row h-full items-center  justify-center gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem] max-w-[1440px] 2xl:m-auto '>
                <li className='flex flex-col items-center justify-center lg:justify-start lg:items-start gap-6 lg:gap-[3rem]'>
                    <div className='flex flex-col items-center justify-center text-center lg:text-start lg:items-start gap-3 '>
                        <img src={logo} alt="" className='z-10 w-[10rem] lg:w-[15rem] selector' />
                        <p className='text-[1.5rem] lg:text-[2.3rem] xl:text-[3rem]'>The community   for <span className='md:hidden ' ><br /> collectors and enthusiasts </span></p>
                        <p className='text-[1.5rem] lg:text-[2.3rem] xl:text-[3rem] hidden md:flex -mt-5'>collectors and enthusiasts</p>
                    </div>

                    <ul className='flex flex-col items-center justify-center gap-3 lg:items-start lg:justify-start -mt-8 lg:mt-0'>
                        <li className='uppercase text-[1.5rem] font-bold hidden lg:flex'>
                            Download now!
                        </li>

                        <li className=' flex-col lg:flex-row w-[15rem] md:w-[18rem] xl:w-[23rem] m-auto lg:m-0 items-center justify-center lg:items-start lg:justify-start gap-4 hidden lg:flex'>
                            <button onClick={() => handleOpen2("xs")} >
                                <img src={googlePlay} alt=""
                                    className='w-[10rem] xl:w-[13rem] selector' />
                            </button>

                            <button onClick={() => handleOpen("xs")}>
                                <img src={appStore} alt="" className='w-[10rem]  xl:w-[13rem] selector' />
                            </button>

                        </li>
                    </ul>
                </li>
                <li className='flex justify-end items-end h-full '>
                    <img src={phone} alt="" className='w-[10rem] lg:w-[23rem] xl:w-[25rem] selector' />
                </li>
                <li className='uppercase text-[1.3rem] font-bold lg:hidden '>
                    Download now!
                </li>
                <li className='flex  w-[15rem] md:w-[18rem] xl:w-[23rem] m-auto lg:m-0 items-center justify-center lg:items-start lg:justify-start gap-10 lg:hidden -mt-4'>
                    <a href="https://play.google.com/store/apps/details?id=com.xdeal" target='_blank'>
                        <img src={googlePlayLogo} alt=""
                            className='w-[2.5rem] selector' />
                    </a>
                    <a href="https://apps.apple.com/ph/app/xure/id6463198157" target='_blank' >
                        <img src={appStoreLogo} alt="" className='w-[2.8rem] selector' />
                    </a>
                </li>
            </ul>
            <Dialog open={size === "xs"} size={size || "md"} handler={handleOpen}>
                <DialogBody className='text-center'>
                    <img src={testFlightQR} alt="" />
                    <p>Scan to Download</p>
                </DialogBody>
            </Dialog>
            <Dialog open={size2 === "xs"} size={size2 || "md"} handler={handleOpen2}>
                <DialogBody className='text-center'>
                    <img src={googlePlayQR} alt="" />
                    <p>Scan to Download</p>
                </DialogBody>
            </Dialog>
        </div>

    )
}

export default XureAppHome
