import React, { useState, useEffect } from 'react';
import logo from '../../assets/logo.png'
import phone from '../../assets/phone.png'
import bgSection from '../../assets/bg/bg-section-2.png'
import sectionAssetGIF from '../../assets/section-asset/asset-gif-2.gif'

import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"

import AOS from 'aos';
import 'aos/dist/aos.css';

import TextTransition, { presets } from 'react-text-transition';

export default function XureSystemSection() {

    const words = ["Connect Collectors Worldwide", "Appraisal and Authentication at your fingertips", "Find rare finds, discover great deals"];

    useEffect(() => {
        AOS.init();
    }, []);

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            2000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <div className='bg-[#ffffff] background-image-1 min-w-screen w-full flex overflow-hidden selector'>
            <div className='z-10 flex flex-col lg:flex-row items-center w-full justify-end gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem]'>
                <div className='parallax_section items-center w-full h-full px-10 xl:px-20 lg:py-20 py-10 '>
                    <div className='flex w-full flex-col lg:flex-row h-full items-center gap-[0rem] justify-center max-w-[1440px] 2xl:m-auto '>
                        <div className='flex flex-col justify-center text-center lg:text-left w-full lg:justify-start text-[#121212] leading-normal text-xl lg:text-[1.5rem] xl:text-5xl md:flex' data-aos="fade-down" data-aos-duration="1000">
                            From recording and sharing collections to appraisals and purchases,
                            Xure connects collectors, experts, and collectible stores in a thriving community.
                        </div>
                        <div className='flex justify-end items-end h-full ' data-aos="fade-left" data-aos-duration="1000">
                            <img src={sectionAssetGIF} alt="" className='w-[100rem] lg:w-[50rem] xl:w-[100rem] selector' />
                            {/* <video autoPlay muted loop className='w-[20rem] lg:w-[50rem] xl:w-[100rem]'>
                                <source src={sectionAssetGIF} />
                            </video> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
