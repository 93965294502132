import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import xpertIcon from '../../assets/xpert-icon.png'
import xtoreIcon from '../../assets/xtore-icon.png'
import assetSection2 from '../../assets/section-asset/asset-section-2.png'
import { Link } from 'react-scroll';
import { Parallax } from 'react-scroll-parallax';

import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"
export default function XtoreSection() {
    useEffect(() => {
        AOS.init();
    }, []);

    const [size, setSize] = useState(null);
    const handleOpen = (value) => setSize(value);
    return (
        <div id="xtoreSection" className='background-image-4 min-w-screen w-full flex overflow-hidden selector'>
            <div className='z-10 flex flex-col lg:flex-row items-center w-full justify-end gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem]'>
                <div className='parallax_section items-center w-full h-full px-10 md:pt-[0rem] xl:px-20 lg:py-20 py-10 '>
                    {/* <div className='parallax_bg parallax_img'></div> */}
                    <div className='flex w-full flex-col h-full items-center  justify-center gap-[2rem]  max-w-[1440px] 2xl:m-auto'>
                        <div className='flex flex-col justify-end w-full lg:justify-end lg:items-end gap-3'>
                            <div className='flex flex-row-reverse justify-center lg:justify-end items-center gap-3 lg:flex-row '>
                                <div className='text-center text-[#ffffff] font-semibold text-2xl lg:text-[2.4rem] xl:text-[2.5rem]' data-aos="fade-right" data-aos-duration="500">
                                    THE XTORE
                                </div>
                                <div data-aos="fade-left" data-aos-duration="500">
                                    <img src={xtoreIcon} className='w-[2rem] h-[2rem]' />
                                </div>
                            </div>
                            <p className='lg:text-end text-center text-[#ffffff] text-[1rem] lg:text-[1.3rem] xl:text-[1.3rem]' data-aos="fade-up" data-aos-duration="1000">
                                Great Finds, Hard-to-Find Items, Trusted Merchants.
                            </p>
                        </div>
                        <div className='flex flex-col justify-end w-full lg:justify-end lg:items-end gap-3'>
                            <div className='content-card w-full flex flex-col lg:flex-row lg:gap-[5rem] gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="1000">
                                <div className='number-metric-display-button uppercase lg:w-1/2 md:text-[2rem] text-3xl text-center lg:text-right'>
                                    <span>
                                        Target
                                        <br />Your
                                        <br />Market
                                    </span>
                                </div>
                                <div className='headline-content lg:w-1/2 text-[#ffffff] text-center lg:text-left lg:text-[1.3rem] xl:text-[1.3rem]'>
                                    <h4>
                                        Reach the right audience effortlessly. Xtore connects you directly with a niche community of collectors eager to discover and purchase your unique items.
                                    </h4>
                                </div>
                            </div>
                            <div className='content-card w-full flex flex-col lg:flex-row lg:gap-[5rem] gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="1200">
                                <div className='number-metric-display-button uppercase lg:w-1/2 md:text-[2rem] text-3xl text-center lg:text-right'>
                                    <span>
                                        Level-up
                                        <br />The
                                        <br />Game
                                    </span>
                                </div>
                                <div className='headline-content lg:w-1/2 text-[#ffffff] text-center lg:text-left lg:text-[1.3rem] xl:text-[1.3rem]'>
                                    <h4>
                                        Elevate your selling experience with exclusive tools designed for collectors. Manage your listings, track performance, and stay ahead in the collectors' marketplace.
                                    </h4>
                                </div>
                            </div>
                            <div className='content-card w-full flex flex-col lg:flex-row lg:gap-[5rem] gap-[2rem] items-center' data-aos="fade-up" data-aos-duration="1300">
                                <div className='number-metric-display-button uppercase lg:w-1/2 md:text-[2rem] text-3xl text-center lg:text-right'>
                                    <span>
                                        Manage
                                        <br />Anywhere
                                        <br />Anytime
                                    </span>
                                </div>
                                <div className='headline-content lg:w-1/2 text-[#ffffff] text-center lg:text-left lg:text-[1.3rem] xl:text-[1.3rem]'>
                                    <h4>
                                        Stay in control of your store from any device. Xtore’s intuitive dashboard lets you manage your business anytime, anywhere, with real-time updates and insights.
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper w-full flex justify-end w-full " data-aos="fade-up" data-aos-duration="1300">
                            <button onClick={() => handleOpen("xs")} className='btn-apply sm:w-full sm:text-xs md:text-lg' href="#">
                                Be An Accredited Xtore!
                            </button>
                        </div>
                    </div>
                    <div className='benefits-section-bg' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" />
                </div>

            </div>

            <Dialog open={size === "xs"} size={size || "md"} handler={handleOpen}>
                <DialogBody className='text-center'>
                    <p className='text-[#121212]'>
                        Hold on! Store registration will open soon!
                    </p>
                </DialogBody>
            </Dialog>
        </div>
    );
}
