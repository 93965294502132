import loadingGIF from '../assets/section-asset/loading-gif.gif';

const Loading = () => {
    return (
        <div className="bg-[#000000] lg:py-10 w-full min-w-screen h-full min-h-screen items-center justify-center flex flex-col">
            <ul className='w-full h-full flex justify-center items-center flex-col'>
                <div className=''>
                    <img src={loadingGIF} className='loader-container w-[30rem] h-[30rem]' />
                </div>
            </ul>
        </div>
    )
}
export default Loading;