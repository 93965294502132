import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import googlePlayQR from '../../assets/googlePlayQR.png'
import googlePlay2 from '../../assets/googlePlay2.png'
import appStore2 from '../../assets/appStore2.png'
import testFlightQR from '../../assets/testFlightQR.png'
import assetSection from '../../assets/section-asset/asset-section-8.png'
import sectionAssetGIF1 from '../../assets/section-asset/asset-gif-3.gif'
import sectionAssetGIF2 from '../../assets/section-asset/asset-gif-3-mobile.gif'

import AOS from 'aos';

import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"
import { Parallax } from 'react-scroll-parallax';

export default function CallToActionSection() {


    const [size, setSize] = useState(null);
    const handleOpen = (value) => setSize(value);
    const [size2, setSize2] = useState(null);
    const handleOpen2 = (value) => setSize2(value);

    const words = ["Xhibit", "Track", "Record"];

    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [letters1, setLetters1] = useState([]);
    const [letters2, setLetters2] = useState([]);

    useEffect(() => {
        setLetters1(splitLetters(words[currentWordIndex]));

        const interval = setInterval(() => {
            changeWord();
        }, 5000);

        return () => clearInterval(interval);
    }, [currentWordIndex]);

    const splitLetters = (word) => {
        return word.split("").map((letter, i) => ({
            letter,
            className: "letter in",
            key: i,
        }));
    };

    const changeWord = () => {
        animateLettersOut(letters1, setLetters1);

        setTimeout(() => {
            const nextIndex = (currentWordIndex + 1) % words.length;
            setCurrentWordIndex(nextIndex);

            setLetters1(splitLetters(words[nextIndex]).map(letter => ({ ...letter, className: "letter behind" })));

            setTimeout(() => {
                setLetters1(prevLetters => prevLetters.map(letter => ({ ...letter, className: "letter in" })));
            }, 340);
        }, 1000);
    };

    const animateLettersOut = (letters, setLetters) => {
        setLetters(letters.map((letter, i) => ({
            ...letter,
            className: "letter out",
        })));
    };


    useEffect(() => {
        AOS.init();
    }, []);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Track if it's mobile

    // Update the state when the window is resized
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    // Set up the resize event listener on mount and cleanup on unmount
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Set the video source based on screen size
    const videoSource = isMobile ? sectionAssetGIF2 : sectionAssetGIF1;

    return (
        <div className='bg-[#121212] min-w-screen 2xl:min-h-screen 2xl:h-full w-full flex overflow-hidden selector'>
            <div className='z-10 flex flex-col lg:flex-row items-center w-full justify-start gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem]'>
                <div className='parallax_section items-center w-full h-full px-10 xl:px-20 lg:py-20 py-20 '>

                    <div className='flex w-full flex-col lg:flex-row h-full items-center  justify-center gap-[2rem]  max-w-[1440px] 2xl:m-auto'>

                        <Parallax speed={10} className='parallax_bg_1 parallax_img w-full h-full'>
                            <img src={videoSource} key={videoSource} className="w-full h-full" />
                        </Parallax>
                        <div className='flex flex-col justify-start w-full lg:justify-start lg:start-end gap-3'>
                            <div className='flex justify-start items-center gap-3 flex-row '>
                                <div className='uppercase tracking-wide lg:text-start text-center text-[#f5f5f5] leading-normal lg:font-semibold font-normal text-xl md:text-[1.6rem] lg:text-[2rem] xl:text-[2rem]' data-aos="fade-down" data-aos-duration="500">
                                    <span className='text-[#f5f5f5]'>
                                        Download Xure now and join the ultimate collector’s network!
                                    </span>
                                </div>
                            </div>
                            <div className='hidden lg:block text-[#f5f5f5] flex flex-col items-center justify-center gap-1 lg:gap-4 mt-2 lg:mt-4 lg:items-start lg:justify-start'>
                                <div className='mb-2 justify-start uppercase text-sm lg:text-[1.3rem] xl:text-[1.5rem] font-normal lg:flex text-right lg:text-right w-full' data-aos="fade-right" data-aos-duration="1500">
                                    Download now!
                                </div>

                                <div className='flex-row w-full m-auto lg:m-0 items-center justify-end lg:items-start lg:justify-start gap-3 flex'>
                                    <button onClick={() => handleOpen2("xs")} data-aos="fade-right" data-aos-offset="50" data-aos-duration="1500">
                                        <img src={googlePlay2} alt=""
                                            className='w-[5rem] lg:w-[8rem] xl:w-[13rem] mr-2 selector' />
                                    </button>

                                    <button onClick={() => handleOpen("xs")} data-aos="fade-left" data-aos-offset="50" data-aos-duration="1500">
                                        <img src={appStore2} alt="" className='w-[5rem] lg:w-[8rem] xl:w-[13rem] selector' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col h-full justify-end w-full lg:justify-end lg:items-end gap-3'>
                            <img src={assetSection} alt="" className='w-[100%] lg:w-[100%] xl:w-[100%]  2xl:w-[100%] selector' data-aos="fade-left" data-aos-duration="1000" />
                            <div className='lg:hidden block text-[#f5f5f5] w-full flex flex-col items-center justify-center gap-1  mt-2 '>
                                <div className='justify-center uppercase text-lg lg:text-[1.3rem] xl:text-[1.5rem] font-normal lg:flex text-center lg:text-right w-full' data-aos="fade-right" data-aos-duration="1500">
                                    Download now!
                                </div>

                                <div className=' flex-row w-full m-auto lg:m-0 items-center justify-center gap-3 flex'>
                                    <button onClick={() => handleOpen2("xs")} data-aos="fade-right" data-aos-offset="50" data-aos-duration="1500">
                                        <img src={googlePlay2} alt=""
                                            className='w-[8rem] lg:w-[8rem] xl:w-[13rem] mr-2 selector' />
                                    </button>

                                    <button onClick={() => handleOpen("xs")} data-aos="fade-left" data-aos-offset="50" data-aos-duration="1500">
                                        <img src={appStore2} alt="" className='w-[8rem] lg:w-[10rem] xl:w-[13rem] selector' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={size === "xs"} size={size || "md"} handler={handleOpen}>
                <DialogBody className='text-center'>
                    <img src={testFlightQR} alt="" />
                    <p>Scan to Download</p>
                </DialogBody>
            </Dialog>
            <Dialog open={size2 === "xs"} size={size2 || "md"} handler={handleOpen2}>
                <DialogBody className='text-center'>
                    <img src={googlePlayQR} alt="" />
                    <p>Scan to Download</p>
                </DialogBody>
            </Dialog>
        </div>
    );
}
