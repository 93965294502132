import React, { useState, useEffect } from 'react';
import phone from '../../assets/phone.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import TypingAnimation from '../../utils/TypingAnimation';
import googlePlayQR from '../../assets/googlePlayQR.png'
import googlePlayLogo from '../../assets/googlePlayLogo.png'
import appStoreLogo from '../../assets/appStoreLogo.png'
import googlePlay2 from '../../assets/googlePlay2.png'
import appStore2 from '../../assets/appStore2.png'
import testFlightQR from '../../assets/testFlightQR.png'
import assetSection from '../../assets/section-asset/asset-section-7.png'
import assetSection1 from '../../assets/section-asset/1.png'
import assetSection2 from '../../assets/section-asset/2.png'
import assetSection3 from '../../assets/section-asset/3.png'

import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"
import { Parallax } from 'react-scroll-parallax';

export default function XureSocialSection() {


    const [size, setSize] = useState(null);
    const handleOpen = (value) => setSize(value);
    const [size2, setSize2] = useState(null);
    const handleOpen2 = (value) => setSize2(value);

    const words = ["Xhibit", "Track", "Record"];

    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [letters1, setLetters1] = useState([]);
    const [letters2, setLetters2] = useState([]);

    useEffect(() => {
        setLetters1(splitLetters(words[currentWordIndex]));

        const interval = setInterval(() => {
            changeWord();
        }, 2000);

        return () => clearInterval(interval);
    }, [currentWordIndex]);

    const splitLetters = (word) => {
        return word.split("").map((letter, i) => ({
            letter,
            className: "letter in",
            key: i,
        }));
    };

    const changeWord = () => {
        animateLettersOut(letters1, setLetters1);

        setTimeout(() => {
            const nextIndex = (currentWordIndex + 1) % words.length;
            setCurrentWordIndex(nextIndex);

            setLetters1(splitLetters(words[nextIndex]).map(letter => ({ ...letter, className: "letter behind" })));

            setTimeout(() => {
                setLetters1(prevLetters => prevLetters.map(letter => ({ ...letter, className: "letter in" })));
            }, 340);
        }, 1000);
    };

    const animateLettersOut = (letters, setLetters) => {
        setLetters(letters.map((letter, i) => ({
            ...letter,
            className: "letter out",
        })));
    };


    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className='bg-[#121212] background-image-1 min-w-screen w-full flex overflow-hidden selector'>
            <div className='z-10 flex flex-col lg:flex-row items-center w-full justify-start gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem]'>
                <div className='parallax_section items-center w-full h-full px-10 xl:px-20 py-10 '>
                    <div className='flex w-full flex-col flex-col-reverse lg:flex-row h-full items-center  justify-between max-w-[1440px] 2xl:m-auto'>
                        <div className=' '>
                            <img src={assetSection} alt="" className='selector w-[50rem] lg:w-[100rem] xl:w-[50rem]' data-aos="fade-up" data-aos-duration="1000" />
                        </div>
                        <div className='flex flex-col justify-center w-full lg:justify-end lg:start-end gap-3'>
                            <p className='text-[#f5f5f5] justify-center lg:justify-end text-base text-center lg:text-right lg:text-[1.5rem] xl:text-[1.5rem] md:flex -mb-4 lg:mb-1' data-aos="fade-down" data-aos-duration="1000">Collectors Connect!</p>

                            <div className='uppercase text-center lg:text-end text-[#ceae7b] md:leading-none leading:normal font-semibold text-4xl lg:text-[3.4rem] xl:text-[3.5rem]' data-aos="fade-down" data-aos-duration="500">
                                {letters1.map(({ letter, className, key }) => (
                                    <span key={key} className={className}>{letter}</span>
                                ))}
                                <span className='text-[#f5f5f5]'>
                                    <br /> your
                                    <br /> Collections
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={size === "xs"} size={size || "md"} handler={handleOpen}>
                <DialogBody className='text-center'>
                    <img src={testFlightQR} alt="" />
                    <p>Scan to Download</p>
                </DialogBody>
            </Dialog>
            <Dialog open={size2 === "xs"} size={size2 || "md"} handler={handleOpen2}>
                <DialogBody className='text-center'>
                    <img src={googlePlayQR} alt="" />
                    <p>Scan to Download</p>
                </DialogBody>
            </Dialog>
        </div>
    );
}
