import React, { useState, useEffect, useRef } from 'react';
import logo from '../../assets/logo.png'
import phone from '../../assets/phone.png'
import bgSection from '../../assets/bg/bg-section-8.png'
import sectionAsset from '../../assets/section-asset/asset-section-1.png'

import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"

import AOS from 'aos';
import 'aos/dist/aos.css';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitType from 'split-type';
import Lenis from 'lenis';
import { Parallax, ParallaxBanner, useParallax } from 'react-scroll-parallax';

gsap.registerPlugin(ScrollTrigger);

export default function PropositionSection() {
    useEffect(() => {
        AOS.init();
    }, []);

    const revealRefs = useRef([]);

    useEffect(() => {
        const lenis = new Lenis();

        const raf = (time) => {
            lenis.raf(time);
            requestAnimationFrame(raf);
        };

        requestAnimationFrame(raf);

        lenis.on('scroll', (e) => {
            console.log(e);
        });

        revealRefs.current.forEach((char) => {
            const bg = char.dataset.bgColor;
            const fg = char.dataset.fgColor;

            const text = new SplitType(char, { types: 'chars' });

            gsap.fromTo(
                text.chars,
                { color: bg },
                {
                    color: fg,
                    duration: 0.3,
                    stagger: 0.02,
                    scrollTrigger: {
                        trigger: char,
                        start: 'top 90%',
                        end: 'top 20%',
                        scrub: true,
                        markers: false,
                        toggleActions: 'play play reverse reverse',
                    },
                }
            );
        });

        return () => {
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
            lenis.destroy();
        };
    }, []);

    const mage = useParallax < HTMLDivElement > ({
        scale: [0.5, 1, 'easeInQuad'],
    });

    return (
        <div className="bg-[#f5f5f5] background-image-1 min-w-screen w-full flex overflow-hidden selector">

            <div className='min-w-screen w-full flex overflow-hidden selector'>
                <div className='z-10 flex flex-col items-center w-full justify-center gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem]'>
                    <div className='parallax_section items-center w-full h-full px-10 xl:px-20 lg:py-20 py-10 '>

                        <div className=' w-full flex flex-col sm:flex-col-reverse md:flex-col-reverse lg:flex-row h-full sm:items-start sm:justify-center gap-[1rem] md:gap-[2rem] max-w-[1440px] 2xl:m-auto'>
                            {/* <Parallax speed={10}
                                className='w-full h-full'
                                data-aos="fade-right"
                                data-aos-duration="500"
                                translatey={[-0, 20]}
                            >
                                <img src={bgSection} />
                            </Parallax> */}
                            <div className=' '>
                                <img src={bgSection} alt="" className='selector w-[50rem] lg:w-[70rem] xl:w-[80rem]' data-aos="fade-right" data-aos-duration="1000" />
                            </div>
                            <div className='flex flex-col xl:items-end w-full justify-center items-center'>
                                <div
                                    className='leading-normal xl:text-end text-center text-[#121212] text-[1.4rem] md:text-[2.3rem] lg:text-[2rem] xl:text-[2.5rem] 2xl:text-[3rem]'
                                    data-aos="fade-left"
                                    data-aos-duration="500"
                                    data-aos-offset="100"
                                    data-aos-easing="ease-in-sine"
                                >

                                    The social networking app which
                                    <br /> brings collectors, appraisers,
                                    <br /> and collectible stores
                                    <br />in one powerful platform.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
