import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import xpertIcon from '../../assets/xpert-icon.png'
import xtoreIcon from '../../assets/xtore-icon.png'
import assetSection2 from '../../assets/section-asset/asset-section-2.png'
import { Link } from 'react-scroll';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

export default function ContactUsSection() {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <ParallaxBanner
            layers={[
                { image: '../../assets/New-Xure-Logo.png', speed: 10 },
                {
                    speed: -5,
                    children: (
                        <div id='contactUs' className="absolute inset-0 h-full flex items-center justify-center">

                            <div className='min-w-screen min-h-screen w-full flex overflow-hidden selector'>
                                <div className='z-10 flex flex-col lg:flex-row items-center w-full justify-end gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem]'>
                                    <div className='parallax_section items-center w-full h-full px-10 md:pt-[0rem] xl:px-20 lg:py-20 py-10 '>
                                        <Parallax speed={10} className='parallax_bg_1 parallax_img' />
                                        <div className='flex w-full flex-col h-full items-center  justify-center gap-[2rem] '>
                                            <div className='flex flex-col justify-end w-full lg:justify-end lg:items-end gap-3'>
                                                <div className='flex justify-end items-center gap-3 flex-row '>
                                                    <div className='text-center text-[#ffffff] font-semibold text-2xl lg:text-[2.4rem] xl:text-[2.5rem]' data-aos="fade-right" data-aos-duration="500">
                                                        “Download Xure now and join the ultimate collector’s network!”
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                },
                { image: '../../assets/New-Xure-Logo.png', speed: -10 },
            ]}
            className="aspect-[3/1] bg-[#000]"
        />
    );
}
