import React, { useState, useEffect } from 'react';

import assetSection2 from '../../assets/section-asset/asset-section-2.png'
import xtoreIcon from '../../assets/xtore-icon.png'
import logoXure from '../../assets/New-Xure-Logo.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function BenefitsOfXure() {
    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <div id="benefitsOfXure" className='bg-[#f5f5f5] min-w-screen w-full flex overflow-hidden selector'>
            <div className='z-10 flex flex-col lg:flex-row items-center w-full justify-end gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem]'>
                <div className='parallax_section items-center w-full h-full px-10 xl:px-20 lg:py-20 py-10 '>
                    <div className='flex w-full flex-col h-full items-center  justify-center max-w-[1440px] 2xl:m-auto'>
                        <div className='flex flex-col justify-center w-full lg:justify-end lg:items-end'>
                            <div className='flex lg:justify-end justify-center items-center gap-2 flex-row '>
                                <div className='uppercase text-[#161616] font-semibold text-2xl lg:text-[2.4rem] xl:text-[2.5rem]' data-aos="fade-down" data-aos-duration="1000">
                                    Benefits of
                                </div>
                                <div className='uppercase text-transparent font-semibold text-2xl lg:text-[2.4rem] xl:text-[2.5rem] bg-clip-text bg-gradient-to-r from-[#F7D091] to-[#917A55]' data-aos="fade-down" data-aos-duration="1200">
                                    XURE
                                </div>
                            </div>
                            <p className='lg:text-end text-center text-[#161616] text-[1rem] lg:text-[1.3rem] xl:text-[1.3rem]' data-aos="fade-down" data-aos-duration="2500">
                                Making it easier to navigate the collectibles market confidently.
                            </p>
                        </div>
                        <div className='flex flex-col gap-y-1 lg:gap-y-4 justify-center'>
                            <div class="flex flex-col lg:flex-row gap-x-4 mt-10 justify-center">
                                <div className='content-card-1 flex flex-col gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="1000">
                                    <div className='number-metric-display-button lg:leading-none font-normal uppercase  md:text-[2rem] text-xl text-center'>
                                        New Avenue to Sell Products
                                    </div>
                                    <div className='headline-content p-5 flex flex-col justify-center items-center text-center leading-normal text-xs lg:text-[1rem] xl:text-[1rem]'>
                                        Expand beyond traditional retail channels by listing your products in Xure’s dedicated marketplace;
                                        <br />The Xchange. Which connects you directly to serious collectors and enthusiasts seeking exclusive items.
                                    </div>
                                </div>
                                <div className='content-card-2 flex flex-col gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="1500">
                                    <div className='number-metric-display-button font-normal uppercase  md:text-[2rem] text-xl text-center'>
                                        Targeted Market of Collectors
                                    </div>
                                    <div className='headline-content p-5 flex flex-col justify-center items-center text-center leading-normal text-xs lg:text-[1rem] xl:text-[1rem]'>
                                        Xure’s user base consists of passionate collectors from various niches—whether it’s
                                        luxury items, sneakers, watches, or other rare collectibles, your products will be
                                        seen by people who care deeply about what you offer.
                                    </div>
                                </div>
                                <div className='content-card-1 flex flex-col gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="2000">
                                    <div className='number-metric-display-button font-normal uppercase  md:text-[2rem] text-xl text-center'>
                                        In-App Promotions & Xclusives
                                    </div>
                                    <div className='headline-content p-5 flex flex-col justify-center items-center text-center leading-normal text-xs lg:text-[1rem] xl:text-[1rem]'>
                                        Engage your customers through in-app notifications, alerts, and exclusive promotions. Be featured in Xclusives, our curated events that drive high engagement and sales for official partner stores.
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col lg:flex-row gap-x-4 justify-center'>

                                <div className='content-card-1 flex flex-col gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="1000">
                                    <div className='number-metric-display-button font-normal uppercase  md:text-[2rem] text-xl text-center'>
                                        Customer Loyalty Program
                                    </div>
                                    <div className='headline-content p-5 flex flex-col justify-center items-center text-center leading-normal text-xs lg:text-[1rem] xl:text-[1rem]'>
                                        Keep your customers coming back with our Customer Loyalty Program. You’ll be able to offer exclusive deals and rewards to your loyal patrons, keeping them invested in your store.
                                    </div>
                                </div>
                                <div className='content-card-2 flex flex-col gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="1500">
                                    <div className='number-metric-display-button font-normal uppercase  md:text-[2rem] text-xl text-center'>
                                        Xure-Provided Vouchers
                                    </div>
                                    <div className='headline-content p-5 flex flex-col justify-center items-center text-center leading-normal text-xs lg:text-[1rem] xl:text-[1rem]'>
                                        Boost your sales by offering Xure-provided vouchers to your customers, creating incentives for more frequent purchases.
                                    </div>
                                </div>
                                {/* <div className='content-card-1 flex flex-col gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="2000">
                                <div className='number-metric-display-button uppercase  md:text-[2rem] text-3xl text-center'>
                                    Employee Engagement Through Commission Program
                                </div>
                                <div className='headline-content text-[#ffffff] text-center lg:text-left lg:text-[1.3rem] xl:text-[1.3rem]'>
                                    <h4>
                                        Motivate your staff with Xure’s commission program, allowing employees to earn rewards for driving sales, enhancing both engagement and performance.
                                    </h4>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
