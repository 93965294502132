import { React, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Spinner,
} from "@material-tailwind/react"
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdOutlineError } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import axios from "axios"
import { API_BASE_URL } from '../utils/varConstant';
export default function ContactUs() {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [website, setWebsite] = useState("")
    const [company, setCompany] = useState("")
    const [message, setMessage] = useState("")
    const [size, setSize] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOpen = (value) => setSize(value);


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const endpoint = `${API_BASE_URL}/xdeal/SendHelpAndSupportEmail`;
            const support = {
                email: email,
                firstName: firstName,
                lastName: lastName,
                company: company,
                website: website,
                phoneNumber: phoneNumber,
                message: message
            }
            console.log(support)
            const response = await axios.post(endpoint, support);
            if (response.status === 200 && response.data === "Success") {
                handleOpen("xs")
                setSuccess(true)
                setFirstName("")
                setLastName("")
                setEmail("")
                setCompany("")
                setWebsite("")
                setPhoneNumber("")
                setMessage("")
            }
            else {
                handleOpen("xs")
                setSuccess(false)

            }


        } catch (error) {
            console.log(error)
            handleOpen("xs")
            setSuccess(false)
        }
        finally {
            setLoading(false)
        }

    }



    return (

        <div className="bg-[#f5f5f5] lg:py-10 w-full min-w-screen h-full min-h-screen items-center justify-center flex flex-col" id="contactUs">
            <ul className='w-full h-full flex justify-center items-center flex-col gap-3'>
                <li className='text-center font-bold mt-10 lg:mt-1 lg:text-5xl md:text-4xl sm:text-3xl capitalize'>
                    Get in Touch
                </li>
                <li className='font-[500] md:text-lg text-xs sm:text-md'>
                    Send us a message and we'll get back to you soon.
                </li>
                <li className=' lg:w-[60rem] md:w-[30rem]  w-[18rem] rounded-xl p-5'>
                    <form className="flex flex-col md:gap-3 gap-2" onSubmit={(e) => handleSubmit(e)}>
                        {/* Name */}
                        <div className='font-[500] flex flex-col md:flex-row items-center justify-start md:gap-5 gap-2'>
                            <div className='w-full'>
                                <input
                                    className="font-[500] placeholder:font-[500] placeholder:text-gary-400 block bg-transparent w-full  bg-white py-3 px-3 shadow-lg focus:outline-none border-2 border-white sm:text-sm rounded-lg  focus:border-[#ceae7b] "
                                    placeholder="First name *"
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => { setFirstName(e.target.value) }}
                                    required />
                            </div>
                            <div className='w-full'>
                                <input
                                    className="font-[500] placeholder:font-[500] placeholder:text-gary-400 block bg-transparent w-full bg-white py-3 px-3 shadow-lg focus:outline-none border-2 border-white sm:text-sm rounded-lg  focus:border-[#ceae7b]  "
                                    placeholder="Last name *"
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => { setLastName(e.target.value) }}
                                    required />
                            </div>
                        </div>
                        {/* Email */}
                        <div className='font-[500] flex flex-col md:flex-row items-center justify-start md:gap-5 gap-2'>
                            <div className='w-full'>
                                <input
                                    className="font-[500] placeholder:font-[500] placeholder:text-gary-400 block bg-transparent w-full bg-white py-3 px-3 shadow-lg focus:outline-none border-2 border-white sm:text-sm rounded-lg focus:border-[#ceae7b] "
                                    placeholder="Email *"
                                    type="email"
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    required />
                            </div>
                            <div className='w-full'>
                                <input
                                    className="font-[500] placeholder:font-[500] placeholder:text-gary-400 block bg-transparent w-full bg-white py-3 px-3 shadow-lg focus:outline-none border-2 border-white sm:text-sm rounded-lg  focus:border-[#ceae7b]"
                                    placeholder="Phone number (optional)"
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        {/* Website */}
                        <div className='font-[500] flex flex-col md:flex-row items-center justify-start md:gap-5 gap-2'>
                            <div className='w-full'>
                                <input
                                    className="font-[500] placeholder:font-[500] placeholder:text-gary-400 block bg-transparent w-full bg-white py-3 px-3 shadow-lg focus:outline-none border-2 border-white sm:text-sm rounded-lg  focus:border-[#ceae7b]"
                                    placeholder="Website (optional)"
                                    type="url"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)} />
                            </div>
                            <div className='w-full'>
                                <input
                                    className="font-[500] placeholder:font-[500] placeholder:text-gary-400 block bg-transparent w-full bg-white py-3 px-3 shadow-lg focus:outline-none border-2 border-white sm:text-sm rounded-lg  focus:border-[#ceae7b]"
                                    placeholder="Company (optional)"
                                    type="text"
                                    value={company}
                                    onChange={(e) => { setCompany(e.target.value) }} />
                            </div>
                        </div>
                        <div className='font-[500]'>
                            <textarea
                                id="message" rows="4"
                                className="font-[500] placeholder:font-[500] placeholder:text-gary-400 block bg-transparent w-full rounded-xl bg-white py-3 px-3 shadow-lg focus:outline-none border-2 border-white focus:border-[#ceae7b] sm:text-sm" placeholder="What can we do for you? *"
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)} />
                        </div>
                        <div className="w-full flex justify-end">
                            <Button className='hover:bg-[#ceae7b]  font-semibold rounded-xl border-2 hover:border-white border-[#ceae7b] bg-white text-[#ceae7b] hover:text-white shadow-lg flex items-center justify-center gap-2' type='submit' disabled={loading} loading={loading}>
                                Submit {loading && <Spinner />}
                            </Button>
                        </div>

                    </form>
                </li>
            </ul>
            <Dialog open={size === "xs"} size={size || "md"} handler={handleOpen}>
                <DialogHeader className='flex w-full items-end justify-end pr-8 border-b-2 text-[#161616]'>
                    <button onClick={() => handleOpen()}>
                        <VscError />
                    </button>
                </DialogHeader>
                <DialogBody className='text-center'>
                    <ul className='flex flex-col w-full items-center justify-center gap-4'>
                        <li className='text-[5rem] text-[#ceae7b]'>
                            {success ? <FaRegCircleCheck /> : <MdOutlineError />}
                        </li>
                        <li className='text-[#161616]'>
                            {success ? (
                                <p>Message sent! <br />Thank you for submitting your concern.</p>
                            ) : (
                                <p>Message not sent! <br />Please try again.</p>
                            )}
                        </li>
                    </ul>


                </DialogBody>
                <DialogFooter className='pr-5 border-t-2'>
                    <button className='hover:bg-[#ceae7b] px-4 py-2 font-semibold rounded-xl border-2 hover:border-white border-[#ceae7b] bg-white text-[#ceae7b] hover:text-white shadow-lg' type='button' onClick={() => handleOpen()}>Close</button>
                </DialogFooter>
            </Dialog>
        </div>
    );
}