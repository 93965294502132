import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import logoX from '../../assets/Logo-X.png';
import { useNavigate } from "react-router-dom"
import { useViewportScroll, useTransform } from 'framer-motion';
import logo from '../../assets/logo.png'
import favicon from '../../assets/favicon.png'
import NewMobileMenu from './NewMobileMenu';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"
export default function NewNavigation() {

    const navigate = useNavigate();
    const { scrollYProgress } = useViewportScroll();
    const scaleX = useTransform(scrollYProgress, [0, 1], [0, 1]);

    const [activeLink, setActiveLink] = useState('newhome');

    const handleSetActiveLink = (link) => {
        setActiveLink(link);
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [size, setSize] = useState(null);
    const handleOpen = (value) => setSize(value);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) { // Adjust scroll position if needed
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`absolute w-full min-w-screen z-30 lg:px-10 flex items-center py-8 px-7 lg:px-10 xl:px-20`}>
            <div class="w-full items-center justify-between max-w-[1440px] 2xl:m-auto ">
                <div className="flex items-center justify-between h-14">
                    {/* Site branding */}
                    <div className="shrink-0 mr-4">
                        <Link href="newhome" className="block" aria-label="Cruip">
                            <img src={logo} className="h-8 mr-3" alt="Logo" />
                        </Link>
                    </div>

                    {/* Desktop navigation */}
                    <div className="bg-[#1e1e1ec9] max-w-2xl lg:block md:hidden sm:hidden md:flex md:grow py-4 px-7 md:px-10 xl:px-10 rounded-full border-solid border-2 border-[#2e2e2e]">
                        {/* Desktop sign in links */}
                        <ul className="flex grow justify-center flex-wrap items-center uppercase font-[500] md:text-sm space-x-8 pr-3">
                            <Link
                                to="newhome"
                                spy={true}
                                smooth={true}
                                duration={600}
                                onSetActive={handleSetActiveLink}
                            >
                                <li
                                    className={`cursor-pointer tracking-widest block py-2 pl-3 pr-4 rounded hover:text-[#ceae7b] md:p-0 ${activeLink === 'newhome' ? 'text-[#ceae7b]' : 'text-white'
                                        }`}
                                >
                                    Home
                                </li>
                            </Link>
                            <Link
                                to='beAnXpert'
                                spy={true}
                                smooth={true}
                                duration={600}
                                onSetActive={handleSetActiveLink}
                            >
                                <li
                                    className={`cursor-pointer block tracking-widest py-2 pl-3 pr-4 rounded hover:text-[#ceae7b] md:p-0 ${activeLink === 'XureDealWorks' ? 'text-[#ceae7b]' : 'text-white'
                                        }`}
                                >
                                    Apply As An Xpert
                                </li>
                            </Link>
                            {/* <Link
                                spy={true}
                                smooth={true}
                                duration={600}
                                onSetActive={handleSetActiveLink}
                            >
                                <li
                                    className={`cursor-pointer block py-2 pl-3 pr-4 rounded hover:text-[#ceae7b] md:p-0 ${activeLink === 'XureDealWorks' ? 'text-[#ceae7b]' : 'text-white'
                                        }`}
                                >
                                    About Us
                                </li>
                            </Link> */}
                            <Link
                                to='xtoreSection'
                                spy={true}
                                smooth={true}
                                duration={600}
                                onSetActive={handleSetActiveLink}
                            >
                                <li
                                    className={`cursor-pointer tracking-widest block py-2 pl-3 pr-4 rounded hover:text-[#ceae7b] md:p-0 ${activeLink === 'XureDealWorks' ? 'text-[#ceae7b]' : 'text-white'
                                        }`}
                                >
                                    Be An Accredited Xtore
                                </li>
                            </Link>

                        </ul>
                    </div>
                    <div className="shrink-0 ml-4 lg:block hidden">
                        <button
                            // onClick={() => navigate('collectorsQuiz')}
                            onClick={() => handleOpen("xs")}
                            className="hover:bg-[#ceae7b] px-3 py-3 rounded-xl border-2 hover:border-white border-[#ceae7b] text-[#ceae7b] hover:text-white shadow-lg flex items-center justify-center gap-2"
                        >
                            Discover your inner Collector
                        </button>
                    </div>
                </div>
            </div>
            {/* Mobile navigation */}
            <NewMobileMenu
                activeLink={activeLink}
                handleSetActiveLink={handleSetActiveLink}
                mobileNavOpen={isMenuOpen}
                handleMenuToggle={handleMenuToggle}
            />
            <motion.div className="h-1 bg-[#ceae7b] z-50" style={{ scaleX }} />

            <Dialog open={size === "xs"} size={size || "md"} handler={handleOpen}>
                <DialogBody className='text-center'>
                    <p className='text-[#121212]'>
                        Quiz loading soon!
                    </p>
                </DialogBody>
            </Dialog>
        </header>
    );
}
