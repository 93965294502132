import React, { useState, useEffect } from 'react';

import { Typewriter } from 'react-simple-typewriter';


import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Home() {

  const targetDate = new Date('2023-08-10T01:30:00');

  const calculateTimeRemaining = () => {
    const currentTime = new Date();
    const timeDifference = targetDate - currentTime;

    if (timeDifference > 0) {
      const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
      const days = Math.floor((timeDifference / (1000 * 60 * 60 * 24)) % 30);
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
      const seconds = Math.floor((timeDifference / 1000) % 60);

      return {
        months: months.toString().padStart(2, '0'),
        days: days.toString().padStart(2, '0'),
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0'),
      };
    }

    return {
      months: '00',
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalCountdown = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(intervalCountdown);
    };
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="max-w-screen-xl h-screen flex items-center justify-center w-full min-w-full" id="home1">
    <div className="flex flex-col justify-center lg:text-start sm:text-center items-center w-full h-screen relative">
      {/* <img
        src={cover}
        alt=""
        className="w-full h-full object-cover hidden md:block object-top mix-blend-overlay absolute brightness-[60%] bg-fixed"
        loading="lazy"
      />
      <img
        src={cover2}
        alt=""
        className="w-full h-full md:hidden object-cover object-top mix-blend-overlay absolute brightness-[60%] bg-fixed"
        loading="lazy"
      /> */}
      <div className="background-image" />

        <div
          className="lg:mb-7 3xl:mb-4 sm:mb-2"
          data-aos="fade-right"
          data-aos-duration="1000"
          
          
        >
          <label className="lg:text-5xl 2xl:text-6xl sm:text-4xl md:text-5xl text-gray-300 font-semibold content-title">
            Authenticate <span></span>
          </label>
        </div>
        <div
          className="md:mb-7 3xl:mb-4 sm:mb-5"
          data-aos="fade-right"
          data-aos-duration="1500"
          
        >
          <label
            className="lg:text-5xl 2xl:text-6xl sm:text-4xl md:text-5xl text-gray-300 font-semibold content-title"
          >
            Showcase and Trade <span></span>
          </label>
        </div>
        <div
          className="h-16"
          data-aos="zoom-in"
          data-aos-duration="3000"
          data-aos-easing="ease-in-sine"
        >
          <label className="text-5xl text-[#ceae7b] font-[500] z-20">
            <Typewriter
              words={['Shoes', 'Watches', 'Bags', 'Jewelry', 'Collectibles', 'Artworks']}
              loop={true}
              cursor
              cursorStyle='|'
              typeSpeed={100}
              deleteSpeed={50}
              delaySpeed={500}
            />
          </label>
        </div>
        <div
          className="mb-5  z-20"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-sine"

        >
          <label className="lg:text-2xl 2xl:text-2xl sm:text-1xl md:text-2xl text-[white] font-[400] capitalize">
            The premier collectible haven
          </label>
        </div>
        <div
          className="mb-2"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          <label className="lg:text-[1.2rem] 2xl:text-1xl sm:text-1xl md:text-2xl text-[white] font-[400] z-20">
            Launching in
          </label>
        </div>
        <div className="flex flex-row justify-center md:gap-6 gap-3"  data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">
          <div
            className="flex-col flex text-center text-base"
            
          >
            <label className="text-[#ceae7b] font-[500] lg:text-5xl md:text-5xl sm:text-4xl z-20">
              {timeRemaining.months}
            </label>
            <label className="text-gray-100 font-normal lg:text-md sm:text-sm z-20">
              Month
            </label>
          </div>
          <div
            className="flex-col flex text-center text-base"
           
          >
            <label className="text-[#ceae7b] font-[500] lg:text-5xl md:text-5xl sm:text-4xl z-20">
              {timeRemaining.days}
            </label>
            <label className="text-gray-100 font-normal lg:text-md sm:text-sm z-20">
              Days
            </label>
          </div>
          <div
            className="flex-col flex text-center text-base"
            
          >
            <label className="text-[#ceae7b] font-[500] lg:text-5xl md:text-5xl sm:text-4xl z-20">
              {timeRemaining.hours}
            </label>
            <label className="text-gray-100 font-normal lg:text-md sm:text-sm z-20">
              Hours
            </label>
          </div>
          <div
            className="flex-col flex text-center text-base"
           
          >
            <label className="text-[#ceae7b] font-[500] lg:text-5xl md:text-5xl sm:text-4xl z-20">
              :
            </label>
          </div>
          <div
            className="flex-col flex text-center text-base"
           
          >
            <label className="text-[#ceae7b] font-[500] lg:text-5xl md:text-5xl sm:text-4xl z-20">
              {timeRemaining.minutes}
            </label>
            <label className="text-gray-100 font-normal lg:text-md sm:text-sm z-20">
              Minutes
            </label>
          </div>
          <div
            className="flex-col flex text-center text-base"
           
          >
            <label className="text-[#ceae7b] font-[500] lg:text-5xl md:text-5xl sm:text-4xl z-20">
              :
            </label>
          </div>
          <div
            className="flex-col flex text-center text-base"
            
          >
            <label className="text-[#ceae7b] font-[500] lg:text-5xl md:text-5xl sm:text-4xl z-20 ">
              {timeRemaining.seconds}
            </label>
            <label className="text-gray-100 font-normal lg:text-md sm:text-sm z-20">
              Seconds
            </label>
          </div>
        </div>

      </div>


    </div>
  );
}
