import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import xpertIcon from '../../assets/xpert-icon.png'
import xtoreIcon from '../../assets/xtore-icon.png'
import assetSection5 from '../../assets/section-asset/asset-section-5.png'
import { Link } from 'react-scroll';

import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"
export default function XpertSuiteSection() {
    useEffect(() => {
        AOS.init();
    }, []);

    const [size, setSize] = useState(null);
    const handleOpen = (value) => setSize(value);
    return (
        <div id='beAnXpert' className='background-image-4 min-w-screen w-full flex overflow-hidden selector'>
            <div className='z-10 flex flex-col lg:flex-row items-center w-full justify-end gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem]'>
                <div className='parallax_section items-center w-full h-full px-10 xl:px-20 lg:py-20 py-10 '>
                    <div className='flex w-full flex-col h-full items-center  justify-center gap-[2rem]  max-w-[1440px] 2xl:m-auto'>
                        <div className='flex flex-col justify-start w-full lg:justify-start lg:start-end gap-3'>
                            <div className='flex justify-center lg:justify-start items-center gap-3 flex-row '>

                                <div data-aos="fade-right" data-aos-duration="500">
                                    <img src={xpertIcon} className='w-[2rem] h-[2rem]' />
                                </div>
                                <div className='uppercase text-center text-[#ffffff] font-semibold text-2xl lg:text-[2.4rem] xl:text-[2.5rem]' data-aos="fade-left" data-aos-duration="500">
                                    THE Xpert Suite
                                </div>
                            </div>
                            <p className='text-center lg:text-start text-[#ffffff] text-[1rem] lg:text-[1.3rem] xl:text-[1.3rem]' data-aos="fade-up" data-aos-duration="1000">
                                For individuals and companies with expertise
                                <br className='hidden lg:block' />in authenticating and appraising collectibles.
                            </p>
                        </div>
                        <div className='flex flex-col justify-end w-full lg:justify-end lg:items-end gap-3'>
                            <div className='content-card w-full flex flex-col-reverse flex-col lg:flex-row lg:gap-[5rem] gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="1200">
                                <div className='headline-content lg:w-1/2 text-[#ffffff] text-center lg:text-right lg:text-[1.3rem] xl:text-[1.3rem]'>
                                    <h4>
                                        Protect your intellectual property with secure, traceable appraisals, backed by technology that safeguards your contributions and insights.
                                    </h4>
                                </div>
                                <div className='number-metric-display-button uppercase lg:w-1/2  md:text-[2rem] text-3xl text-center lg:text-left'>
                                    <span>
                                        Protect
                                        <br />Your
                                        <br />Work
                                    </span>
                                </div>
                            </div>
                            <div className='content-card w-full flex flex-col-reverse flex-col lg:flex-row lg:gap-[5rem] gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="1200">


                                <div className='headline-content lg:w-1/2 text-[#ffffff] text-center lg:text-right lg:text-[1.3rem] xl:text-[1.3rem]'>
                                    <h4>
                                        Offer specialized expertise and become the go-to professional for collectors seeking authentication and valuation for their prized collections.
                                    </h4>
                                </div>
                                <div className='number-metric-display-button uppercase lg:w-1/2  md:text-[2rem] text-3xl text-center lg:text-left'>
                                    <span>
                                        Be
                                        <br />The
                                        <br />Solution
                                    </span>
                                </div>
                            </div>
                            <div className='content-card w-full flex flex-col-reverse flex-col lg:flex-row lg:gap-[5rem] gap-[3rem] items-center' data-aos="fade-up" data-aos-duration="1300">


                                <div className='headline-content lg:w-1/2 text-[#ffffff] text-center lg:text-right lg:text-[1.3rem] xl:text-[1.3rem]'>
                                    <h4>
                                        Grow your network of clients and collaborators by joining a vibrant community of collectors, appraisers, and artists through the Xure App.
                                    </h4>
                                </div>
                                <div className='number-metric-display-button uppercase lg:w-1/2  md:text-[2rem] text-3xl text-center lg:text-left'>
                                    <span>
                                        Maintain
                                        <br />and Expand
                                        <br />Community
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper w-full flex justify-start w-full " data-aos="fade-up" data-aos-duration="1300">
                            <button onClick={() => handleOpen("xs")} className='btn-apply sm:w-full sm:text-xs md:text-lg' href="#">
                                Apply as An Xpert!
                            </button>
                        </div>
                    </div>
                    <div className='benefits-section-bg-1' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" />
                </div>

            </div>
            <Dialog open={size === "xs"} size={size || "md"} handler={handleOpen}>
                <DialogBody className='text-center'>
                    <p className='text-[#121212]'>
                        Application will open soon!
                    </p>
                </DialogBody>
            </Dialog>
        </div>
    );
}
