import React, { useState, useEffect } from 'react';
import logo from '../../assets/logo.png'
import googlePlay from '../../assets/googlePlay.png'
import appStore from '../../assets/appStore.png'
import phone from '../../assets/section-asset/asset-section-6.png'
import testFlightQR from '../../assets/testFlightQR.png'
import googlePlayQR from '../../assets/googlePlayQR.png'
import googlePlayLogo from '../../assets/googlePlayLogo.png'
import appStoreLogo from '../../assets/appStoreLogo.png'
import bgSection from '../../assets/bg/4.png'

import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Parallax } from 'react-parallax';
import TextTransition, { presets } from 'react-text-transition';

export default function NewHome() {

    const [size, setSize] = useState(null);
    const handleOpen = (value) => setSize(value);
    const [size2, setSize2] = useState(null);
    const handleOpen2 = (value) => setSize2(value);

    const words1 = ["Discover", "DEFINE", "HEAL", "Unleash", "Ignite", "Awaken", "Embrace", "Unlock", "Find", "Celebrate"];
    const words2 = ["Collector", "DESIRE", "CHILD", "Curator", "Passion", "Historian", "Visionary", "Treasure", "Storyteller", "Legacy"];

    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [letters1, setLetters1] = useState([]);
    const [letters2, setLetters2] = useState([]);

    const letters3 = words2.map((word, index) =>
        word.split("").map((letter, idx) => ({ letter, className: 'word-letter', key: `${index}-${idx}` }))
    );

    useEffect(() => {
        setLetters1(splitLetters(words1[currentWordIndex]));
        setLetters2(splitLetters(words2[currentWordIndex]));

        const interval = setInterval(() => {
            changeWord();
        }, 3000);

        return () => clearInterval(interval);
    }, [currentWordIndex]);

    const splitLetters = (word) => {
        return word.split("").map((letter, i) => ({
            letter,
            className: "letter in",
            key: i,
        }));
    };

    const changeWord = () => {
        animateLettersOut(letters1, setLetters1);
        animateLettersOut(letters2, setLetters2);

        setTimeout(() => {
            const nextIndex = (currentWordIndex + 1) % words1.length;
            setCurrentWordIndex(nextIndex);

            setLetters1(splitLetters(words1[nextIndex]).map(letter => ({ ...letter, className: "letter behind" })));
            setLetters2(splitLetters(words2[nextIndex]).map(letter => ({ ...letter, className: "letter behind" })));

            setTimeout(() => {
                setLetters1(prevLetters => prevLetters.map(letter => ({ ...letter, className: "letter in" })));
                setLetters2(prevLetters => prevLetters.map(letter => ({ ...letter, className: "letter in" })));
            }, 340);
        }, 1000);
    };

    const animateLettersOut = (letters, setLetters) => {
        setLetters(letters.map((letter, i) => ({
            ...letter,
            className: "letter out",
        })));
    };

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);


    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Parallax strength={500} bgImage={bgSection}>
            <div id='newhome' className='flex items-center justify-between min-w-screen xl:min-h-screen xl:h-full w-full px-10 md:pt-[10rem] md:pb-[20] xl:px-20 lg:pt-[10rem] lg:pb-20 pb-10 pt-[10rem] overflow-hidden selector'>

                <ul className='flex flex-col lg:flex-row items-center w-full justify-between gap-[2rem] text-white  overflow-hidden xl:gap-[18rem] lg:gap-[5rem] max-w-[1440px] 2xl:m-auto '>
                    <li className='flex flex-col items-center w-full justify-center lg:justify-start lg:items-start gap-2 lg:gap-[0rem]'>
                        <div className='w-full block lg:block flex flex-col justify-center items-center gap-y-3'>

                        </div>
                        {/* <div className='uppercase text-[2.3rem]  tracking-wide md:text-[3rem] lg:text-[3.5rem] xl:text-[3.7rem] 2xl:[4rem] font-semibold text-center lg:text-left xl:text-left text-[#ceae7b] word-animation' data-aos="zoom-in-up" data-aos-duration="1000">
                            {letters1.map(({ letter, className, key }) => (
                                <span key={key} className={className}>{letter}</span>
                            ))}
                        </div>
                        <div className='uppercase text-[1.5rem] tracking-wide lg:text-[2.3rem] xl:text-[3rem] text-center lg:text-left xl:text-left' data-aos="zoom-in-up" data-aos-duration="2000">
                            your inner
                        </div>
                        <div className='uppercase text-[2.3rem]  tracking-wide md:text-[3rem] lg:text-[3.5rem] xl:text-[3.7rem] 2xl:[4rem] font-semibold text-center lg:text-left xl:text-left text-[#ceae7b] word-animation' data-aos="zoom-in-up" data-aos-duration="3000">
                            {letters2.map(({ letter, className, key }) => (
                                <span key={key} className={className}>{letter}</span>
                            ))}
                        </div>
                        <div className='transition-animation' data-aos="zoom-in-up" data-aos-duration="3000">
                            <TextTransition className='uppercase text-[2.3rem]  tracking-wide md:text-[3rem] lg:text-[3.5rem] xl:text-[3.7rem] 2xl:[4rem] font-semibold text-center lg:text-left xl:text-left text-[#ceae7b]' springConfig={presets.gentle}>{words2[index % words2.length]}</TextTransition>
                        </div> */}
                        <div className='w-full hidden lg:block flex flex-col justify-center items-center '>
                            <div className='word-animation uppercase leading-normal text-[2.3rem] tracking-wide md:text-[2.7rem] lg:text-[2.8rem] xl:text-[3.2rem] 2xl:text-[4rem] font-semibold text-center lg:text-left xl:text-left text-[#ceae7b]' data-aos="zoom-in-up" data-aos-duration="3000">
                                {letters1.map(({ letter, className, key }) => (
                                    <span key={key} className={className}>{letter}</span>
                                ))}
                            </div>
                            <div className='leading-normal uppercase text-[1.7rem] tracking-wide lg:text-[2.3rem] xl:text-[2.8rem] text-center lg:text-left xl:text-left' data-aos="zoom-in-up" data-aos-duration="2000">
                                your inner
                            </div>
                            <div className='word-animation uppercase leading-normal text-[2.3rem] tracking-wide md:text-[2.5rem] lg:text-[2.8rem] xl:text-[3.2rem] 2xl:text-[4rem] font-semibold text-center lg:text-left xl:text-left text-[#ceae7b]' data-aos="zoom-in-up" data-aos-duration="3000">
                                {letters2.map(({ letter, className, key }) => (
                                    <span key={key} className={className}>{letter}</span>
                                ))}
                            </div>
                        </div>
                        <div className='w-full block lg:hidden flex flex-col justify-center items-center gap-y-3'>
                            <div className='uppercase mb-4 md:mb-6 leading-none text-[2.3rem] tracking-wide md:text-[2.7rem] lg:text-[3.5rem] xl:text-[3.7rem] 2xl:[4rem] font-semibold text-center lg:text-left xl:text-left text-[#ceae7b]' data-aos="zoom-in-up" data-aos-duration="3000">
                                <TextTransition className='' springConfig={presets.gentle}>{words1[index % words1.length]}</TextTransition>
                            </div>
                            <div className='leading-none uppercase text-[1.7rem] tracking-wide lg:text-[2.3rem] xl:text-[3rem] text-center lg:text-left xl:text-left' data-aos="zoom-in-up" data-aos-duration="2000">
                                your inner
                            </div>
                            <div className='uppercase leading-none text-[2.3rem] tracking-wide md:text-[2.7rem] lg:text-[3.5rem] xl:text-[3.7rem] 2xl:text-[4rem] font-semibold text-center lg:text-left xl:text-left text-[#ceae7b]' data-aos="zoom-in-up" data-aos-duration="3000">
                                <TextTransition springConfig={presets.gentle}>{words2[index % words2.length]}</TextTransition>
                            </div>
                        </div>
                    </li>
                    <li className='flex justify-end items-end h-full ' data-aos="fade-left">
                        <img src={phone} alt="" className='w-[100rem] md:w-[50rem] lg:w-[50rem] 2xl:w-[80rem] xl:w-[70rem] selector' />
                    </li>
                </ul>
            </div>
        </Parallax>
    );
}
