import React, { useState } from "react"
// import logo from "../assets/Logo-X.png"
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaAppStoreIos } from "react-icons/fa";
import { BsFacebook, BsInstagram, BsTiktok, BsYoutube, BsTwitterX, BsPinMap, BsPinMapFill } from "react-icons/bs";
import { Link } from 'react-scroll';
import { BsFillTelephoneFill, BsChatDotsFill } from "react-icons/bs";
import logo from '../assets/logo.png'
import { AiFillMail, AiOutlineCloseCircle } from "react-icons/ai";

import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react"

import axios from "axios";
import { API_BASE_URL } from "../utils/varConstant";

import '../styles/mainStyle.css';

export default function Footer() {

    const [size, setSize] = useState(null);
    const handleOpen = (value) => {
        setSize(value);
    };

    const handleOpen2 = (value) => {
        setSize(value);
    }
    return (
        <div className="w-full z-30 bg-[#161616] " id="footer">

            <div className=" max-w-[1440px] 2xl:m-auto border-b-2  border-gray-800 w-full flex-col lg:flex-row flex justify-evenly items-start py-5 text-[#676767] px-10 xl:px-20 ">

                {/* fist container */}
                <div className="flex w-full  lg:justify-start xl:justify-end ">
                    {/* logo */}
                    <ul className="w-full text-center items-center lg:items-start 3xl:items-center flex flex-col gap-2 text-sm md:text-base">
                        <li className="w-[6rem] mb-2 lg:ml-[85px] 3xl:ml-[25px]">
                            <img src={logo} alt="logo" />
                        </li>
                        <li className=" font-[700] mb-2">
                            Authenticate, Showcase and Trade
                        </li>
                        <li className=" font-[500] w-[280px]">
                            Indulge in a world of prized possessions. Buy, sell, and display your authenticated treasures. Connect with fellow enthusiasts and build your unique collection
                        </li>

                    </ul>
                </div>
                <div className="flex w-full  lg:justify-start xl:justify-end ">
                    {/* logo */}
                    <ul className="w-full text-center items-center flex flex-col gap-2 text-sm md:text-base">
                        <li className=" text-neutral-400  font-[700] uppercase  justify-center">
                            Social Media
                        </li>
                        <li className="flex gap-4 text-2xl w-full justify-center">
                            <a href="https://www.facebook.com/XureApp" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]">
                                <BsFacebook />
                            </a>
                            <a href="https://www.instagram.com/xureofficial/" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]">
                                <BsInstagram />
                            </a>
                            <a href="https://www.tiktok.com/@officialxure" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]">
                                <BsTiktok />
                            </a>
                            {/* <a href="https://twitter.com/xureofficial" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]" >
                                <BsTwitterX />
                            </a> */}
                            {/* <a href="https://www.youtube.com/" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]">
                                <BsYoutube />
                            </a> */}
                        </li>
                    </ul>
                </div>
                <div className="flex w-full justify-center">
                    {/* logo */}
                    <ul className="w-full text-center items-center flex flex-col gap-2 text-sm md:text-base">
                        <li className="text-neutral-400  font-[700] uppercase flex justify-center">
                            Download Now
                        </li>
                        <li className="flex gap-4 text-2xl  w-full justify-center">
                            <a href="https://apps.apple.com/ph/app/xure/id6463198157" target="_blank" className="">
                                <FaAppStoreIos />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.xdeal" target="_blank" className="">
                                <IoLogoGooglePlaystore />
                            </a>
                        </li>
                    </ul>
                </div>

                {/* second container */}
                <div className="flex w-full justify-center ">
                    {/**Resources */}
                    {/* <ul className=" gap-3 flex flex-col  text-center lg:text-start order-1">
                        <li className=" text-neutral-400 font-[700] uppercase">
                            Resources
                        </li>
                        <li className=" hover:text-[#ceae7b] text-neutral-500  font-[500] cursor-pointer ">
                            Attribution
                        </li>
                        <li className=" hover:text-[#ceae7b]  text-neutral-500 font-[500] cursor-pointer ">
                            FAQS
                        </li>
                    </ul> */}

                    {/**Our Company */}
                    {/* <div className="gap-3 flex flex-col md:order-3 lg:order-2">
                        <p className=" text-center lg:text-start  text-neutral-400  font-[700] uppercase">
                            Our Company
                        </p>
                        <div className="flex gap-5">
                            <ul className=" gap-3 flex flex-col xl:w-[7rem] text-center lg:text-start">

                                <Link to="home" spy={true} smooth={true} duration={500}>
                                    <li className=" hover:text-[#ceae7b]  text-neutral-500  font-[500] cursor-pointer ">
                                        Home
                                    </li>
                                </Link>
                                <Link to="XureDealWorks" spy={true} smooth={true} duration={500}>
                                    <li className=" hover:text-[#ceae7b]  text-neutral-500 font-[500] cursor-pointer ">
                                        How it works
                                    </li>
                                </Link>
                                <Link to="contactUs" spy={true} smooth={true} duration={500}>
                                    <li className=" hover:text-[#ceae7b]  text-neutral-500  font-[500] cursor-pointer ">
                                        Contact Us
                                    </li>
                                </Link>
                                <Link to="xhibition" spy={true} smooth={true} duration={500}>
                                    <li className="hover:text-[#ceae7b] text-neutral-500 font-[500] cursor-pointer ">
                                        Xhibition
                                    </li>
                                </Link>
                            </ul>
                            <ul className=" gap-3 flex flex-col xl:w-[7rem] text-center lg:text-start">
                                <Link to="xhibition" spy={true} smooth={true} duration={500}>
                                    <li className="hover:text-[#ceae7b] text-neutral-500 font-[500] cursor-pointer ">
                                        Xhibition
                                    </li>
                                </Link>
                                <Link to="xperts" spy={true} smooth={true} duration={500}>
                                    <li className=" hover:text-[#ceae7b]  text-neutral-500  font-[500] cursor-pointer ">
                                        Xperts
                                    </li>
                                </Link>
                                <Link to="authenticate" spy={true} smooth={true} duration={500}>
                                    <li className=" hover:text-[#ceae7b]  text-neutral-500  font-[500] cursor-pointer ">
                                        Xertify
                                    </li>
                                </Link>
                            </ul>
                        </div>
                    </div> */}
                    {/* Social Media */}
                    <ul className="w-full text-center justify-center items-center flex flex-col gap-2 text-sm md:text-base">
                        <li className="text-neutral-400  font-[700] uppercase flex justify-center">
                            contact us
                        </li>
                        <li className="flex gap-2 items-center w-full justify-center -mt-2">
                            <p><BsFillTelephoneFill /></p>
                            <p>+65-3158-6244</p>
                        </li>
                        <li className="flex gap-2 items-center w-full justify-center -mt-2">
                            <p><AiFillMail /></p>
                            <p>info@xure.app</p>
                        </li>
                    </ul>
                </div>

            </div>

            {/* Lower part */}
            <div className=" max-w-[1440px] 2xl:m-auto flex flex-col lg:flex-row w-full text-center justify-center items-center gap-3 md:gap-2 lg:px-10 xl:px-20 px-2  py-1 text-[11px] md:text-[14px]">
                {/* <ul className="font-[500] text-[#676767]  flex lg:w-[80rem] xl:w-full justify-evenly xl:justify-start  order-2  lg:-ml-7 xl:-ml-[190px] 2xl:-ml-[280px]
                "> */}
                <ul className="font-[500] text-[#676767]  flex lg:w-[80rem] xl:w-full justify-evenly lg:justify-center  order-2  
                ">
                    {/* <li className="hover:underline cursor-pointer border-r-2 border-[#676767] md:px-2 px-1">About Us</li> */}
                    <li className="hover:underline cursor-pointer  border-r-2 border-[#676767] md:px-2 px-1">
                        <button className="hover:text-[#ceae7b]" onClick={() => handleOpen("xs")}>Privacy Policy</button>
                    </li>
                    <li className="hover:underline cursor-pointer  md:px-2 px-1">
                        <button className="hover:text-[#ceae7b]" onClick={() => handleOpen2("xs")}>Terms of Use</button>
                    </li>
                    {/* <li className="hover:underline cursor-pointer px-2">Help Center</li> */}
                </ul>
                <p className="font-[500] text-[#676767] lg:w-full  flex justify-center lg:justify-start  order-1">
                    © 2024 Xure Deal. All rights reserved.
                </p>
                <p className="font-[500] text-[#676767] lg:w-full flex gap-x-1 items-center justify-center lg:text-right xl:justify-end order-3">
                    68 Circular Rd, Singapore 049422<BsPinMapFill />
                </p>
            </div>

            <Dialog open={size === "xs"} size={size || "xs"} handler={handleOpen}>
                <DialogHeader>
                    Privacy Policy
                </DialogHeader>
                <DialogBody className="w-full dialog-body-scroll">
                    <p> Introduction </p>
                    <p> Welcome to <strong>XURE</strong>, a social media platform and online marketplace mobile app (the â€œAppâ€)
                        owned, developed, and operated by
                        <strong>XURE DEAL PRIVATE LIMITED</strong>. In this Privacy Policy, â€œ<strong>XURE DEAL PTE LTD.</strong>â€
                        and the words â€œweâ€, â€œusâ€, and â€œourâ€ refers to
                        <strong>XURE DEAL PRIVATE LIMITED</strong>, a company registered in <strong>SINGAPORE</strong> with company
                        registration number <strong>202319466E</strong> <br /><br />

                        On the other hand, â€œUsersâ€, â€œyouâ€, â€œyourâ€, or â€œyoursâ€ refers to an individual person who registers for an
                        account on the
                        App, for himself or on behalf of a corporate entity who shall be deemed the ultimate User. <br /><br />

                        We are committed to protecting your privacy and ensuring the security of your personal information. This
                        Privacy Policy
                        outlines our practices and processes for the collection, use, storage, permitted disclosure, and protection
                        of your
                        information and your rights thereto. By using the App, you agree to the terms and conditions described
                        below.
                    </p>

                    <h4>
                        1.1 &nbsp; Account Information
                    </h4>
                    <ol type="a">
                        <li>We may use your information for the following purposes:</li>
                        <ol type="i">
                            <li>To provide, maintain, and improve the App's functionality and features.</li>
                            <li>To verify your identity.</li>
                            <li>To personalize your user experience, including showing you content tailored to your declared
                                interests and App usage
                                history.</li>
                            <li>To secure your account in case of suspicious, fraudulent or unauthorized activity.</li>
                            <li>To communicate with you regarding updates, notifications, or responses to your inquiries, concerns,
                                and feedback.</li>
                            <li>To enforce our Terms of Service, other policies, applicable law, or any regulatory order.</li>
                            <li>To aid in the investigation or defense in the event of claims or disputes between Users or between
                                us and a User.</li>
                            <li>To protect our legitimate interests and rights.</li>
                            <li>To develop, test, market, and promote new services, products, applications, and businesses of
                                <strong>XURE DEAL PTE LTD</strong></li>
                        </ol>
                        <li>We may disclose your information to perform our legal or contractual obligations with third parties,
                            such as our payment
                            processors, investors, partners, suppliers, service providers, and vendors; courts and the government
                            authorities; and
                            other Users.</li>
                        <li>We may also use and disclose your information in connection with mergers, acquisitions, investment
                            agreements, joint
                            ventures, assignment, sale or transfer of company assets, consolidation, financing, initial public
                            offering or other
                            similar business transactions.</li>
                        <li>You may specifically withdraw your consent to the processing of your information, or instruct us to
                            withhold any of your
                            information from any person, but this may affect your enjoyment of the full functionalities of the App
                            or the services
                            that we provide.</li>
                        <li>You may request for a copy of your information, upon payment of any reproduction, digitization, or file
                            transmission
                            fees as may be authorized by law.</li>
                    </ol>

                    <h4>
                        1.4 &nbsp; How we safeguard your information
                    </h4>
                    <ol type="a">
                        <li>You are primarily responsible for keeping your Account information updated and secure at all times.
                            Please refrain from
                            sharing your password, item code, unlock code, and/or OTP to any other person. If you are sharing the
                            mobile device on
                            which the App is installed with someone else, make it a habit to log out of your account to prevent any
                            unwanted or
                            unauthorized account activity.</li>
                        <li>You have full control over what you want other Users to see about you or any item in your collection.
                            Please review your
                            privacy settings regularly to ensure that you are sharing only the information that you want other Users
                            to know.</li>
                        <li>If you wish to use face or fingerprint log-in for the App, rest assured that we will not be collecting
                            or storing your
                            biometric information. Instead, we will rely on your device settings to allow you to log-in to the App.
                        </li>
                        <li><i>[Insert overview on the cookies and tracking technologies that you use]</i></li>
                        <li>We implement reasonable and appropriate physical, technical, and organizational security measures, such
                            as but not
                            limited to: <i>[access control, backup solutions, audit log - edit as necessary].</i></li>
                        <li>We will store and retain your information for as long as allowed under Singapore laws for the purposes
                            described herein,
                            unless a longer retention period is authorized or allowed by law.</li>
                    </ol>

                    <h4>
                        1.5 &nbsp; General terms
                    </h4>
                    <ol type="a">
                        <li>This Privacy Policy (including any privacy-related dispute or complaint between us and User) is governed
                            by the laws of
                            Singapore, which is our country of business registration.</li>
                        <li>By using the App, you acknowledge that you have read and consented to this Privacy Policy, including
                            your responsibility
                            for the security and confidentiality of your account.</li>
                        <li>We may update this Privacy Policy to reflect changes in our business interests, practices or applicable
                            law. We will
                            notify you of any significant changes through the App or via email. Please discontinue your use of the
                            App if you do not
                            agree with any of the changes.</li>
                        <li>If you have any questions, concerns, or requests regarding this Privacy Policy or your information that
                            we process,
                            please contact us at <a href="" link="">support@xure.app</a></li>
                    </ol>
                </DialogBody>
            </Dialog>
            <Dialog open={size === "xs"} size={size || "xs"} handler={handleOpen2}>
                <DialogHeader className="text-md flex flex-row jutify-between w-full">
                    Terms of Use for XURE USER APP
                </DialogHeader>
                <DialogBody className="w-full dialog-body-scroll">
                    <p>
                        You are registering for an account on XURE , a social media platform and online marketplace mobile application (the
                        â€œAppâ€) owned, developed, and operated by <strong>XURE DEAL PTE LTD</strong>
                    </p>
                    <p>
                        For your reference, â€œXure Deal PTE LTDâ€ and the words â€œweâ€, â€œusâ€, and â€œourâ€ refers to Xure Deal Private Limited, a
                        company registered in Singapore with company registration number <strong>202319466E</strong>.
                    </p>
                    <p>
                        On the other hand, â€œUserâ€, â€œyouâ€, â€œyourâ€, or â€œyoursâ€ refers to an individual person who registers for an account on the
                        App.
                    </p>

                    <h4>
                        1. &nbsp; Scope
                    </h4>
                    <p>
                        1.1. &nbsp; By logging into and using the App, you confirm that you have read and that you agree to abide by these Terms and any
                        amendments that we may make in the future. We reserve the right to modify or revise these Terms at any time.
                    </p>
                    <p>
                        1.2. &nbsp; Any changes to the Terms will be effective immediately upon posting on our App. Your continued use of our App after the
                        posting of those changes constitutes your acceptance of said changes. Please discontinue using your account and
                        uninstall the App immediately if you do not agree with any of the Terms or any subsequent changes hereto.
                    </p>
                    <p>
                        1.3. &nbsp; Only Users with verified accounts (those who have provided the additional optional information on their account) may buy
                        and/or sell items on the App. Users with unverified accounts may continue to upload posts to their account and view and
                        interact with the posts of other Users.
                    </p>

                    <h4>
                        2. Code of Conduct
                    </h4>
                    <p>
                        2.1. &nbsp; By using the App, you represent and warrant that:

                    </p>

                    <ol type="a">
                        <li>You are at least thirteen (13) years old.</li>
                        <li>You are legally authorized to register for, use, and administer an account with us.</li>
                        <li>You give us your full and informed consent to process your personal information, which shall be subject both to these
                            Terms and to our Privacy Policy.</li>
                        <li>You will provide accurate, updated, and complete information about any item that you upload on the App.</li>
                        <li>You shall maintain the confidentiality of your account information and shall be solely responsible for all activities
                            that occur under your account.</li>
                        <li>You will promptly report to us about any suspicious or fraudulent activity, User, Xure Store, or Xpert. We will endeavor
                            to take appropriate action within the bounds of our policies and applicable laws.</li>
                        <li>You own the items that you post, sell, and/or buy on the App, or otherwise have the authority or permission of the
                            rightful owner to post, sell and/or buy such items.</li>
                        <li>You shall not use any system or tool to decompile, reverse-engineer, extract, scrape, spider, resell, copy, reproduce or
                            modify any data from the App without our prior permission.</li>
                    </ol>

                    <p>
                        2.2 &nbsp; We expect our Users to conduct themselves honorably in all their activities and transactions on the App. Therefore, you
                        agree not to:
                    </p>

                    <ol type="a">
                        <li>Violate these Terms, our Privacy Policy, or applicable laws.</li>
                        <li>Use our App for any prohibited, harmful, or fraudulent activities.</li>
                        <li>Use hateful or offensive speech against other Users, Xure Stores, Xperts, or us.</li>
                        <li>Incite dispute with any User or Xpert, or between us and a User or Xpert.</li>
                        <li>Impersonate another User, Xpert, person or entity.</li>
                    </ol>

                    <p>
                        2.3 &nbsp; We reserve the right to suspend or terminate an account that has been proven to significantly deviate from the user code
                        of conduct. Once your account is permanently terminated, you will no longer be able to access your account or content on
                        the App.
                    </p>

                    <h4>
                        3. Services
                    </h4>

                    <p>
                        3.1. &nbsp; XURE DEAL PTE LTD offers the following services through the App:
                    </p>
                    <ol type="a">
                        <li>As a social media platform: we enable Users to post content and interact with other Users as well as Xperts.</li>
                        <li>As a personal library: Users may upload, catalogue, showcase, and share their collection of toys, memorabilia, watches,
                            bags, books, shoes, and other items</li>
                        <li>As an online marketplace: Users may buy and sell items based on mutually agreed terms.</li>
                        <li>As an authentication platform: Users may engage the services of Xperts who may provide additional details regarding the
                            authenticity, provenance, and value of any item posted on the App.</li>
                        <li>As a payment hub: Users may transact payments directly on the App for greater security.</li>
                    </ol>
                    <p>
                        3.2. &nbsp; Certain features and services on our App may require payment of fees. You agree to pay all applicable fees and charges
                        in accordance with our payment terms. All fees paid to us are non-refundable. You may, at your own expense, recover
                        payments made to a User or Xpert.
                    </p>
                    <p>
                        3.3. &nbsp; Users must exercise caution and conduct due diligence before using any of the above services or entering into a
                        transaction with any User or Xpert. User, Xure Store, or Xpert, as the case may be, shall bear the risk of any loss,
                        damage, theft, defect, or deterioration of any item that a User ships out to an Xpert or another User, and vice versa.
                    </p>
                    <ol type="a">
                        <li>Transacting with an Xpert: Both User and Xpert take full responsibility for the terms of the transaction that they have
                            with each other. If a User decides to engage the services of an Xpert for manual or physical authentication, User takes
                            on the risk and responsibility of preparing, packaging, and shipping the item/s directly to the Xpert for the latterâ€™s
                            assessment. Xpert shall likewise conduct the necessary steps to receive, handle, store, prepare, package, and ship the
                            item back to the User.</li>
                        <li>Transacting with another User and Xure Stores: Users shall communicate directly with each other before finalizing any
                            sale or exchange of items, upon terms that are mutually acceptable to them. They shall each be responsible for the
                            preparation, packaging, and shipping of the item to the correct delivery address and recipient.</li>
                        <li>We may offer payment and delivery options to Users, Xure Stores, and Xperts who wish to send items physically to each
                            other. In that case, User or Xpert, respectively, shall agree to the terms of the payment and delivery service that they
                            choose.</li>
                    </ol>
                    <p>
                        3.4. &nbsp; Users understand that the authentication conducted by an Xpert on any item is based only on such Xpertâ€™s subjective
                        personal opinion at the time of assessment, and does not in any way reflect the opinion of Xure Deal PTE LTD or the
                        persons and entities that manufacture such items.
                    </p>
                    <p>
                        3.5. &nbsp; We shall not be held liable for any transactions that occur outside the App or otherwise violate these Terms.
                    </p>

                    <h4>
                        4. Content
                    </h4>
                    <p>
                        4.1. &nbsp; You retain ownership of any content (including but not limited to posts, photos, videos, messages, authentication
                        certificates, comments, tags, items, documents, collections, fees, addresses, contact information, and listings) you
                        submit to our App.
                    </p>
                    <p>
                        4.2. &nbsp; We do not endorse or verify the identity of Users and do not guarantee the accuracy of user-generated content, including
                        the details, prices, authenticity, or availability of the items displayed on the App. You are solely responsible for
                        finalizing the details (including delivery timeline, fees, and authentication) of any transaction that you make with
                        another User or with an Xpert.
                    </p>
                    <p>
                        4.3. &nbsp; The authentication certificate issued on an item may not be used, displayed, sold, transferred, or assigned to any
                        person other than by the User that requested it.
                    </p>
                    <p>
                        4.4. &nbsp; Xure Deal PTE LTD is not affiliated with, endorsed by, or otherwise connected to the persons or entities that make,
                        manufacture, sell, distribute, or market the items displayed on the App. All brands, logos, trademarks, tradenames, and
                        other intellectual property rights over these items belong to such persons or entities. Any reference to brand names or
                        trademarks on the App is for identification and descriptive purposes only, as inputted by the Users or Xperts
                        themselves.
                    </p>


                    <h4>
                        5. Permissible use of user content and information
                    </h4>
                    <p>
                        5.1. &nbsp; By submitting content, you grant us a worldwide, royalty-free, non-exclusive, irrevocable, and sublicensable right to
                        use, reproduce, modify, adapt, publish, distribute, and display such content on our App.
                    </p>
                    <p>
                        5.2. &nbsp; We may also use an aggregated form of User content to update and improve the features and functionalities of the App,
                        look for and resolve bugs, improve App security, and offer and deliver other services and products (including those
                        provided by third parties) that we believe may be useful or desirable to Users.
                    </p>
                    <p>
                        5.3. &nbsp; For a more comprehensive understanding of how you authorize us to use your information, please view our Privacy Policy.
                    </p>

                    <h4>
                        6. Limitation of liability
                    </h4>
                    <p>
                        6.1. &nbsp; We are not liable for any fake, incomplete, damaged or undelivered items; misrepresentation, negligence, and misconduct
                        of any User, Xure Store, or Xpert; misdelivery or delay in the delivery of any item purchased on the App; or any taxes
                        due, claims, liability, or disputes arising from the interactions and transactions between Users and/or Xperts.
                    </p>
                    <p>
                        6.2. &nbsp; You agree that any claims, disputes, or issues related to transactions that you make on the App, such as but not limited
                        to issues of authenticity, completeness, or damage to items, shall be resolved solely between yourself and the User,
                        Xure Store, or Xpert that you transacted with. We may, but are not obliged to, assist you in reaching out to another
                        User or with an Xpert or Xure Store, but we will not investigate, mediate, or resolve the dispute.
                    </p>
                    <p>
                        6.3. &nbsp; In no event shall the Xure Deal PTE LTD, its owners, officers, directors, employees, representatives, advisors, and
                        consultants be liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss or
                        diminution of profits, revenues, reputation or goodwill, arising out of or related to transactions and activities
                        conducted on the App.
                    </p>
                    <p>
                        6.4. &nbsp; To the fullest extent permitted by applicable law, we disclaim all warranties, whether express or implied, regarding our
                        App, its features, functionalities, and the services made available via the App, including but not limited to the
                        implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We will make reasonable
                        efforts to make the App available to all Users, except for any scheduled downtime or any unforeseen event beyond our
                        control.
                    </p>
                    <p>
                        6.5. &nbsp; Our total liability, if any, for a claim arising out of or in connection with these Terms or your use of our App shall
                        be limited to the amount you paid, if any, to us for the use of our App.
                    </p>

                    <h4>
                        7. Indemnification
                    </h4>
                    <p>
                        7.1. &nbsp; Notwithstanding any other provision in these Terms, we shall not be prevented from seeking injunctive or other equitable
                        relief in court for matters related to our Intellectual Property, the App, or any violation of our legitimate interests
                        and rights.
                    </p>
                    <p>
                        7.2. &nbsp; You agree to indemnify, defend, and hold XURE DEAL PTE LTD, its owners, officers, directors, employees, representatives,
                        advisors, and consultants from and against claims, actions, liabilities, suits or other proceedings, and any and all
                        taxes, losses, judgments, damages, or costs (including reasonable counsel fees and other expenses), arising from or in
                        any way relating to:
                    </p>
                    <ol type="a">
                        <li>Your use of the App, including any content, transactions, or interactions that you make thereon.</li>
                        <li>Your violation of these Terms, Privacy Policy, and/or express written instructions from us.</li>
                        <li>Your omission, negligence, or willful action that results in a violation of our rights or the rights of any third-party.</li>
                        <li>Any actual, alleged, or threatened prohibited, unlawful, fraudulent or suspicious activity that you engage in through or
                            with the aid of the App.</li>
                    </ol>

                    <h4>
                        8. Intellectual Property
                    </h4>
                    <p>
                        8.1. &nbsp; All software, designs, processes, source codes, images, logos, media, trademarks, tradenames, and service marks on the
                        App and in all our marketing materials are the intellectual property of XURE DEAL PTE LTD and/or rightful third-party
                        owner thereof, who have authorized us to use them for specific purposes (collectively the â€œIntellectual Propertyâ€).
                    </p>
                    <p>
                        8.2. &nbsp; A User shall not use, copy, reproduce, assign, republish, upload, adapt, post, transmit, distribute, or modify these
                        Intellectual Property in any way without our prior express written consent.
                    </p>

                    <h4>
                        9. Governing law and dispute resolution
                    </h4>
                    <p>
                        9.1. &nbsp; These Terms shall constitute the binding legal agreement between us and a User regarding the App.
                    </p>
                    <p>
                        9.2. &nbsp; These Terms shall be governed by and construed in accordance with the laws of our country of business registration,
                        <i>SINGAPORE</i>, without regard to its conflict of law principles.
                    </p>
                    <p>
                        9.3. &nbsp; Any dispute, claim, question, or controversy arising out of these Terms or the App shall be referred to and finally
                        resolved by arbitration in accordance with the <i>[clientâ€™s chosen arbitration center in the country of its main business
                            registration]</i>, in accordance with the arbitration rules in force at the time of referral. The seat of arbitration shall
                        be <i>SINGAPORE</i> and the language of arbitration shall be English.
                    </p>

                    <h4>
                        10. Communication
                    </h4>
                    <p>
                        10.1. &nbsp; You agree to receive updates, invitations, news, correspondence, marketing promotions, and other information from us via
                        your registered email address and/or mobile number. Any written legal process shall be served and delivered to you via
                        the same channels, and you shall be deemed to have received them on the day after the date of our delivery.
                    </p>
                    <p>
                        10.2. &nbsp; If you have any questions or concerns about these Terms, please contact us at <a href="" link="" className="font-bold">support@xure.app</a>
                        and we will endeavor to get back to you as soon as possible.
                    </p>
                </DialogBody>
            </Dialog>
        </div>
    )

}