import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from "react";
import './index.css';
import NavigationBar from './components/NavigationBar';
import Home from './components/Home';
// import ItWorks from './components/ItWorks';
// import HowItWorks from './components/HowItWorks';
import ContactUs from './components/Contact';
import Footer from './components/Footer';
import Authenticate from './components/Authenticate';
import Description from './components/Description';
import { ToastContainer } from 'react-toastify';

import { BeatLoader } from 'react-spinners';
import Xperts from './components/Xperts';
import Xchange from './components/Xchange';
import XureAppHome from './components/XureAppHome';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InvalidPage from './components/InvalidPage';
import ShareProfile from './components/ShareProfile';

import NewNavigation from './components/NewScreens/NewNavigation';
import NewHome from './components/NewScreens/NewHome';
import PropositionSection from './components/NewScreens/PropositionSection';
import XureSocialSection from './components/NewScreens/XureSocialSection';
import XpertSuiteSection from './components/NewScreens/XpertSuiteSection';
import XureSystemSection from './components/NewScreens/XureSystemSection';
import XtoreSection from './components/NewScreens/XtoreSection';
import BenefitsOfXure from './components/NewScreens/BenefitsOfXure';
import ContactUsSection from './components/NewScreens/ContactUsSection';
import { ParallaxProvider } from 'react-scroll-parallax';
import CallToActionSection from './components/NewScreens/CallToActionSection';
import CollectorsQuiz from './components/NewScreens/CollectorsQuiz';
import Loading from './utils/Loading';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  // setTimeout(() => {
  //   setIsLoading(false);
  // }, 1500);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 5000)
  }, [])
  if (isLoading) {
    return <Loading />
  }
  const data = () => {
    return (
      <>
        <NewNavigation />
        <NewHome />
        <PropositionSection />
        <XureSocialSection />
        <XureSystemSection />
        <XpertSuiteSection />
        <XtoreSection />
        <BenefitsOfXure />
        <CallToActionSection />
        {/* <ContactUsSection /> */}
        {/* <NavigationBar /> */}
        {/* <XureAppHome /> */}
        {/* <Home /> */}
        {/* <Description /> */}
        {/* <HowItWorks /> */}
        {/* <Xchange /> */}
        {/* <Xperts /> */}
        {/* <Authenticate /> */}
        {/* <ContactUs /> */}
        <Footer />
        {/* <ToastContainer /> */}
      </>
    )
  }

  return (
    <div className="font-General-Sans">
      {/* {isLoading ? (
        <div className="flex items-center justify-center w-full h-screen bg-[#161616]">
          <BeatLoader color="#ceae7b" size={30} />
        </div>
      ) : (
        <> */}

      <ParallaxProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={data()} />
            {/* <Route path='/' element={<ShareProfile />} /> */}
            <Route path='/support' element={<ContactUs />} />
            <Route path='/shareprofile' element={<ShareProfile />} />
            <Route path='*' element={<InvalidPage />} />
            <Route path='/collectorsQuiz' element={<CollectorsQuiz />} />
          </Routes>
        </BrowserRouter>
      </ParallaxProvider>

      {/* </> */}
      {/* )} */}
    </div>
  );
}

export default App;
